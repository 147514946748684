import { Fragment, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { throttle } from 'lodash'
import { ReactComponent as Logo } from '../resource/image/logo.svg'
import { ReactComponent as Hamburger } from '../resource/image/hamburger.svg'
import { ReactComponent as Close } from '../resource/image/close.svg'
import { ReactComponent as HelloDdui } from '../resource/image/hello_ddui.svg'
import './TopBar.css'
import { useLocation, useNavigate } from 'react-router-dom'

const TopBar = () => {
  const [showNavBar, setShowNavBar] = useState(true)
  const lastScrollY = useRef(0) // 아래 useEffect에서 []로 최초 1회 실행이기 때문에 lastScrollY useState로 관리하면 최초 1회만 실행되기 때문에 이후로 값이 변하지 않는다. 그래서 useRef로 관리한다.
  const navigate = useNavigate()
  const location = useLocation()
  const [menu, setMenu] = useState('home')
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showMenu])

  const controlNavbar = () => {
    // console.log("scrolling")
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 639) { // viewport가 640px 이상일 경우 모바일 메뉴 비활성화
        setShowMenu(false)
      }
      if (window.scrollY > 68) {
        if (window.scrollY > lastScrollY.current) {
          // if scroll down hide the navbar
          setShowNavBar(false)
        } else {
          // if scroll up show the navbar
          setShowNavBar(true)
        }
        // remember current page location to use in the next move
        // setLastScrollY(window.scrollY)
        lastScrollY.current = window.scrollY
      } else {
        setShowNavBar(true)
      }
    }
  }

  const handleScroll = throttle(controlNavbar, 200)

  const handleResize = throttle(controlNavbar, 200)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      // cleanup function
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => window.removeEventListener('resize', handleResize)
  }, []) // 빈 의존성 배열을 사용하여 마운트 시에만 이벤트 리스너를 추가

  useEffect(() => {
    // url이 변경될 때마다 실행될 로직 ex) 뒤로 가기 버튼 클릭
    let pathname = location.pathname
    let pathComponents = pathname.split('/')

    // 필요없는 값 지우기.
    pathComponents = pathComponents.filter((el) => {
      return el !== null && el !== undefined && el !== ''
    })
    setMenu(pathComponents[0])
  }, [location])

  return (
    <Fragment>
      <CSSTransition in={showNavBar} timeout={600} classNames={'top-bar'} mountOnEnter unmountOnExit>
        <div className={'top-bar'}>
          <div className={'hidden sm:flex w-[82vw] justify-between items-center'}>
            <Logo onClick={() => navigate(`/home`)} className={'cursor-pointer w-[8.073vw]'}/>
            <div className={'w-[48.698vw] flex justify-between font-[nanum-square-round-eb] text-[1.563vw]'}>
              <div onClick={() => navigate(`/curriculum`)} className={`cursor-pointer ${menu === 'curriculum' ? 'text-[#2046e4]' : ''}`}>커리큘럼</div>
              <div onClick={() => navigate(`/content`)} className={`cursor-pointer ${menu === 'content' ? 'text-[#2046e4]' : ''}`}>콘텐츠</div>
              <div onClick={() => navigate(`/faq`)} className={`cursor-pointer ${menu === 'faq' ? 'text-[#2046e4]' : ''}`}>궁금해요</div>
              <div onClick={() => navigate(`/pc-version`)} className={`cursor-pointer ${menu === 'pc-version' ? 'text-[#2046e4]' : ''}`}>PC버전</div>
              <div onClick={() => navigate(`/ai-courseware`)} className={`cursor-pointer ${menu === 'ai-courseware' ? 'text-[#2046e4]' : ''}`}>AI코스웨어</div>
            </div>
            <div onClick={() => navigate(`/purchase`)}
                 className="flex justify-center items-center w-[8.75vw] h-[3.125vw] bg-[#2046E4] rounded-full text-[#ffffff] font-[nanum-square-round-eb] text-[1.458vw] cursor-pointer">
              상품구매
            </div>
          </div>
          <div className={'w-full h-full sm:hidden relative'}>
            <Logo onClick={() => navigate(`/home`)} className={'w-[20.556vw] h-[7.778vw] absolute top-[50%] -translate-y-1/2 left-[50%] -translate-x-1/2 cursor-pointer'}/>
            <Hamburger onClick={() => toggleMenu()} className={'w-[8.333vw] h-[8.333vw] absolute top-[50%] -translate-y-1/2 right-[4.444vw] cursor-pointer'}/>
            <CSSTransition in={showMenu} timeout={300} classNames={'menu'} mountOnEnter unmountOnExit>
              <div className={'fixed top-0 left-0 right-0 h-screen min-h-[177.778vw] bg-[#ffffff] z-[9999]'}>
                <Close onClick={() => toggleMenu()} className={'w-[8.333vw] h-[8.333vw] absolute top-[2.778vw] right-[4.444vw] cursor-pointer'}/>
                <Logo
                  onClick={() => {
                    navigate(`/home`)
                    setShowMenu(false)
                  }}
                  className={'w-[29.389vw] h-[11.119vw] absolute top-[18.056vw] left-[50%] -translate-x-1/2 cursor-pointer'}/>
                <div
                  onClick={() => {
                    navigate(`/curriculum`)
                    setShowMenu(false)
                  }}
                  className={'absolute top-[46.944vw] left-[4.444vw] font-[nanum-square-round-eb] text-[5.556vw] text-[#3E3E40] leading-none cursor-pointer'}>
                  커리큘럼
                </div>
                <div
                  onClick={() => {
                    navigate(`/content`)
                    setShowMenu(false)
                  }}
                  className={'absolute top-[60.278vw] left-[4.444vw] font-[nanum-square-round-eb] text-[5.556vw] text-[#3E3E40] leading-none cursor-pointer'}>
                  콘텐츠
                </div>
                <div
                  onClick={() => {
                    navigate(`/faq`)
                    setShowMenu(false)
                  }}
                  className={'absolute top-[73.611vw] left-[4.444vw] font-[nanum-square-round-eb] text-[5.556vw] text-[#3E3E40] leading-none cursor-pointer'}>
                  궁금해요
                </div>
                <div
                  onClick={() => {
                    navigate(`/pc-version`)
                    setShowMenu(false)
                  }}
                  className={'absolute top-[86.944vw] left-[4.444vw] font-[nanum-square-round-eb] text-[5.556vw] text-[#3E3E40] leading-none cursor-pointer'}>
                  PC버전
                </div>
                <div
                  onClick={() => {
                    navigate(`/ai-courseware`)
                    setShowMenu(false)
                  }}
                  className={'absolute top-[100.278vw] left-[4.444vw] font-[nanum-square-round-eb] text-[5.556vw] text-[#3E3E40] leading-none cursor-pointer'}>
                  AI코스웨어
                </div>
                <div className={'w-[91.111vw] h-[0.278vw] absolute top-[112.5vw] left-[4.444vw] bg-[#D9D9D9]'}>
                </div>
                <HelloDdui className={'w-[38.889vw] h-[41.686vw] absolute top-[73.889vw] right-[3.611vw]'}/>
                <div
                  onClick={() => {
                    navigate(`/purchase`)
                    setShowMenu(false)
                  }}
                  className={'absolute top-[119.167vw] left-[4.444vw] font-[nanum-square-round-eb] text-[5.556vw] text-[#3E3E40] leading-none cursor-pointer'}>
                  상품 구매
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </CSSTransition>
    </Fragment>
  )
}

export default TopBar
