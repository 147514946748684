import { ReactComponent as InstagramIcon } from '../resource/image/instagram_icon.svg'
import { ReactComponent as YoutubeIcon } from '../resource/image/youtube_icon.svg'
import { ReactComponent as KakaotalkIcon } from '../resource/image/kakaotalk_icon.svg'
import { ReactComponent as NaverblogIcon } from '../resource/image/naverblog_icon.svg'

const Footer = () => {
  return (
    <div className={'w-full h-[131.944vw] sm:h-[21.875vw] relative bg-[#F1F3F8]'}>
      <a
        target={'_blank'}
        href={'http://1promath.co.kr/pages/store/onepromath.php'}
        className={'w-[37.778vw] sm:w-[9.375vw] h-[12.222vw] sm:h-[2.813vw] absolute top-[8.333vw] sm:top-[3.594vw] left-[4.444vw] sm:left-[2.604vw] flex justify-center items-center border-[0.278vw] sm:border-[0.104vw] border-[#3E3E40] font-[pretendard-sb] text-[4.444vw] sm:text-[1.042vw] rounded-[2.778vw] sm:rounded-[0.521vw] leading-none cursor-pointer'}>3일
        무료체험
      </a>
      <a href={'https://www.instagram.com/1promath/'} target={'_blank'} rel={'noopener noreferrer'} className={'absolute top-[30.278vw] sm:top-[3.958vw] right-[84.444vw] sm:right-[11.979vw] block'}>
        <InstagramIcon className={'w-[11.111vw] sm:w-[2.083vw] h-[11.111vw] sm:h-[2.083vw]'}/>
      </a>
      <a href={'https://www.youtube.com/@1promath/featured'} target={'_blank'} rel={'noopener noreferrer'}
         className={'absolute top-[30.278vw] sm:top-[3.958vw] right-[67.778vw] sm:right-[8.854vw] block'}>
        <YoutubeIcon className={'w-[11.111vw] sm:w-[2.083vw] h-[11.111vw] sm:h-[2.083vw]'}/>
      </a>
      <a href={'https://pf.kakao.com/_XPCxab'} target={'_blank'} rel={'noopener noreferrer'} className={'absolute top-[30.278vw] sm:top-[3.958vw] right-[51.111vw] sm:right-[5.729vw] block'}>
        <KakaotalkIcon className={'w-[11.111vw] sm:w-[2.083vw] h-[11.111vw] sm:h-[2.083vw]'}/>
      </a>
      <a href={'https://blog.naver.com/1promath_office'} target={'_blank'} rel={'noopener noreferrer'} className={'absolute top-[30.278vw] sm:top-[3.958vw] right-[34.444vw] sm:right-[2.604vw] block'}>
        <NaverblogIcon className={'w-[11.111vw] sm:w-[2.083vw] h-[11.111vw] sm:h-[2.083vw]'}/>
      </a>
      <div className={'hidden sm:block absolute top-[7.969vw] left-[2.604vw] font-[pretendard-m] text-[0.833vw] leading-none'}>
        (주)매쓰마스터 | 대표이사 김지용 | 주소: 서울특별시 강서구 마곡동 800-1 문영 퀸즈파크11차, B동 6층 609호 | 사업자등록번호 336-88-01167
      </div>
      <div className={'block sm:hidden absolute top-[51.111vw] left-[4.444vw] text-[4.167vw] text-[#4D4D4D]'}>
        (주)매쓰마스터 | 대표이사 김지용
      </div>
      <div className={'block sm:hidden absolute top-[58.611vw] left-[4.444vw] text-[4.167vw] text-[#4D4D4D] leading-tight'}>
        서울특별시 강서구 마곡동 800-1<br/>
        문영 퀸즈파크11차, B동 6층 609호
      </div>
      <div className={'block sm:hidden absolute top-[71.944vw] left-[4.444vw] text-[4.167vw] text-[#4D4D4D] leading-tight'}>
        사업자등록번호 336-88-01167
      </div>
      <div className={'absolute top-[85.833vw] sm:top-[9.948vw] left-[4.444vw] sm:left-[2.604vw] font-[pretendard-m] text-[4.167vw] sm:text-[0.833vw] leading-none'}>
        고객센터 1811-0608 | 1promath@naver.com
      </div>
      <div className={'absolute top-[111.111vw] sm:top-[18.125vw] left-[4.444vw] sm:left-[2.604vw] font-[pretendard-m] text-[4.167vw] sm:text-[0.833vw] text-[#4D4D4D] sm:text-[#000000]leading-none'}>
        Copyright inc.mathmaster.All rights reserved.
      </div>
    </div>
  )
}

export default Footer