import PcversionBg1 from '../../resource/image/pcversion_bg_1.png'
import MobilePcversionBg1 from '../../resource/image/mobile_pcversion_bg_1.png'
import PcVersion2 from '../../resource/image/pcversion_2.png'
import PcVersion3 from '../../resource/image/pcversion_3.png'
import PcVersion4 from '../../resource/image/pcversion_4.png'
import PcVersion5 from '../../resource/image/pcversion_5.png'
import PcVersion6 from '../../resource/image/pcversion_6.png'
import PcVersion7 from '../../resource/image/pcversion_7.png'
import PcVersion8 from '../../resource/image/pcversion_8.png'
import { ReactComponent as Next } from '../../resource/image/next3.svg'
import Footer from '../../component/Footer'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ScrollToTopButton from '../../component/ScrollToTopButton'

const PcVersion = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0) // 페이지 로드 시 스크롤을 최상단으로 이동
  }, [])

  return (
    <div className={'w-[100vw] overflow-hidden'}>
      <div className={'block sm:hidden'}>
        <ScrollToTopButton/>
      </div>
      <div className={'h-auto sm:h-[393.281vw]'}>
        <picture className={'relative'}>
          <source media="(min-width: 640px)" srcSet={PcversionBg1}/>
          <source media="(max-width: 639px)" srcSet={MobilePcversionBg1}/>
          <img src={PcversionBg1} alt="pcversion_bg_1" className={'w-full'}/>
        </picture>
        <div className={'hidden sm:block absolute top-[13.75vw] left-[50%] -translate-x-1/2 font-[nanum-square-round-eb] text-[3.125vw] leading-none'}>
          PC버전으로 즐기는 일프로연산
        </div>
        <div className={'block sm:hidden w-full absolute top-[42.778vw] font-[nanum-square-round-eb] text-[8.889vw] text-center leading-snug'}>
          PC버전으로<br/>
          즐기는 일프로연산
        </div>
        <a
          href={'https://sfm675153-sfm675153.ktcdn.co.kr/onepromath_launcher/%EC%9D%BC%ED%94%84%EB%A1%9C%EC%97%B0%EC%82%B0%20%EC%84%A4%EC%B9%98.exe'} download
          className={'w-[72.222vw] sm:w-[13.49vw] h-[20vw] sm:h-[4.167vw] absolute top-[76.389vw] sm:top-[19.271vw] left-[13.889vw] sm:left-[36.406vw] border-[0.556vw] sm:border-[0.104vw] border-[#2046e4] rounded-[4.167vw] sm:rounded-[0.781vw] flex justify-center items-center font-[nanum-square-round-eb] text-[#2046e4] text-[6.667vw] sm:text-[1.25vw] leading-none'}>
          PC버전 다운로드 <Next className={'w-[3.778vw] sm:w-[0.5vw] h-auto ml-[3.889vw] sm:ml-[0.521vw]'}/>
        </a>
        <div
          onClick={() => {
            navigate('/faq/2')
          }}
          className={'w-[72.222vw] sm:w-[13.49vw] h-[20vw] sm:h-[4.167vw] absolute top-[103.056vw] sm:top-[19.271vw] left-[13.889vw] sm:left-[51.094vw] border-[0.556vw] sm:border-[0.104vw] border-[#2046e4] rounded-[4.167vw] sm:rounded-[0.781vw] flex justify-center items-center font-[nanum-square-round-eb] text-[#2046e4] text-[6.667vw] sm:text-[1.25vw] leading-none cursor-pointer'}>
          일프로연산 가이드 <Next className={'w-[3.778vw] sm:w-[0.5vw] h-auto ml-[3.889vw] sm:ml-[0.521vw]'}/>
        </div>
        <div className={'mt-[35.556vw] sm:mt-[5.625vw] font-[pretendard-sb] text-[8.333vw] sm:text-[2.292vw] text-center leading-none'}>
          설치 및 실행 가이드
        </div>
        <div className={'mt-[11.667vw] sm:mt-[6.667vw] flex justify-center'}>
          <div className={'hidden sm:flex w-[62.5vw]'}>
            <div className={'w-[2.5vw] h-[2.5vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[1.823vw] text-[#ffffff] leading-none'}>
              1
            </div>
            <div className={'ml-[0.625vw]'}>
              <div className={'font-[pretendard-sb] text-[1.458vw]'}>
                홈페이지에서 [PC버전 다운로드] 버튼을 눌러 설치 파일을 다운받은 후 더블 클릭한다.
              </div>
              <div className={'font-[pretendard-l] text-[1.458vw]'}>
                (PC버전은 Windows 버전만 지원 가능합니다.)
              </div>
            </div>
          </div>
          <div className={'block sm:hidden'}>
            <div className={'flex justify-center'}>
              <div className={'w-[13.333vw] h-[13.333vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[9.722vw] text-[#ffffff] leading-none'}>
                1
              </div>
            </div>
            <div className={'mt-[8.889vw] text-center'}>
              <div className={'font-[pretendard-sb] text-[5vw]'}>
                홈페이지에서 [PC버전 다운로드] 버튼을<br/>
                눌러 설치 파일을 다운받은 후 더블 클릭한다.
              </div>
              <div className={'font-[pretendard-l] text-[4.444vw]'}>
                (PC버전은 Windows 버전만 지원 가능합니다.)
              </div>
            </div>
          </div>
        </div>
        <div className={'hidden sm:flex mt-[2.083vw] justify-center'}>
          <div className={'w-[62.5vw] h-[0.208vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'flex justify-center mt-[5.556vw] sm:mt-[2.083vw]'}>
          <img src={PcVersion2} alt={'pc_version_2'} className={'w-[91.111vw] sm:w-[62.5vw]'}/>
        </div>
        <div className={'flex sm:hidden mt-[11.667vw] justify-center'}>
          <div className={'w-[91.111vw] h-[0.556vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'mt-[11.667vw] sm:mt-[5.208vw] flex justify-center'}>
          <div className={'hidden sm:flex w-[62.5vw]'}>
            <div className={'w-[2.5vw] h-[2.5vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[1.823vw] text-[#ffffff] leading-none'}>
              2
            </div>
            <div className={'ml-[0.625vw]'}>
              <div className={'font-[pretendard-sb] text-[1.458vw]'}>
                Windows의 PC 보호 팝업이 뜨면 [추가 정보]를 누른 후 하단에 나타난 [실행] 버튼을 누른다.
              </div>
              <div className={'font-[pretendard-sb] text-[1.458vw]'}>
                이후에 뜨는 사용자 계정 컨트롤 팝업에서 [예] 버튼을 누른다.
              </div>
            </div>
          </div>
          <div className={'block sm:hidden'}>
            <div className={'flex justify-center'}>
              <div className={'w-[13.333vw] h-[13.333vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[9.722vw] text-[#ffffff] leading-none'}>
                2
              </div>
            </div>
            <div className={'mt-[8.889vw] text-center'}>
              <div className={'font-[pretendard-sb] text-[5vw]'}>
                Windows의 PC 보호 팝업이 뜨면<br/>
                [추가 정보]를 누른 후 하단에 나타난<br/>
                [실행] 버튼을 누른다.
              </div>
            </div>
          </div>
        </div>
        <div className={'hidden sm:flex mt-[2.083vw] justify-center'}>
          <div className={'w-[62.5vw] h-[0.208vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'flex justify-center mt-[5.556vw] sm:mt-[2.083vw]'}>
          <img src={PcVersion3} alt={'pc_version_3'} className={'w-[91.111vw] sm:w-[62.5vw]'}/>
        </div>
        <div className={'block sm:hidden mt-[8.889vw] font-[pretendard-sb] text-[5vw] text-center'}>
          이후에 뜨는 사용자 계정 컨트롤 팝업에서<br/>
          [예] 버튼을 누른다.
        </div>
        <div className={'flex justify-center mt-[5.556vw] sm:mt-[2.083vw]'}>
          <img src={PcVersion4} alt={'pc_version_4'} className={'w-[91.111vw] sm:w-[62.5vw]'}/>
        </div>
        <div className={'flex sm:hidden mt-[11.667vw] justify-center'}>
          <div className={'w-[91.111vw] h-[0.556vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'mt-[11.667vw] sm:mt-[5.208vw] flex justify-center'}>
          <div className={'hidden sm:flex w-[62.5vw]'}>
            <div className={'w-[2.5vw] h-[2.5vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[1.823vw] text-[#ffffff] leading-none'}>
              3
            </div>
            <div className={'ml-[0.625vw]'}>
              <div className={'font-[pretendard-sb] text-[1.458vw]'}>
                다음과 설치를 누른 후 설치가 완료되기를 기다린다.
              </div>
            </div>
          </div>
          <div className={'block sm:hidden'}>
            <div className={'flex justify-center'}>
              <div className={'w-[13.333vw] h-[13.333vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[9.722vw] text-[#ffffff] leading-none'}>
                3
              </div>
            </div>
            <div className={'mt-[8.889vw] text-center'}>
              <div className={'font-[pretendard-sb] text-[5vw]'}>
                다음과 설치를 누른 후<br/>
                설치가 완료되기를 기다린다.
              </div>
            </div>
          </div>
        </div>
        <div className={'hidden sm:flex mt-[2.083vw] justify-center'}>
          <div className={'w-[62.5vw] h-[0.208vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'flex justify-center mt-[5.556vw] sm:mt-[2.083vw]'}>
          <img src={PcVersion5} alt={'pc_version_5'} className={'w-[91.111vw] sm:w-[62.5vw]'}/>
        </div>
        <div className={'flex sm:hidden mt-[11.667vw] justify-center'}>
          <div className={'w-[91.111vw] h-[0.556vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'mt-[11.667vw] sm:mt-[5.208vw] flex justify-center'}>
          <div className={'hidden sm:flex w-[62.5vw]'}>
            <div className={'w-[2.5vw] h-[2.5vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[1.823vw] text-[#ffffff] leading-none'}>
              4
            </div>
            <div className={'ml-[0.625vw]'}>
              <div className={'font-[pretendard-sb] text-[1.458vw]'}>
                설치가 완료되면 바탕화면에 생성된 1%연산 아이콘을 더블 클릭하여 실행시킨다.
              </div>
            </div>
          </div>
          <div className={'block sm:hidden'}>
            <div className={'flex justify-center'}>
              <div className={'w-[13.333vw] h-[13.333vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[9.722vw] text-[#ffffff] leading-none'}>
                4
              </div>
            </div>
            <div className={'mt-[8.889vw] text-center'}>
              <div className={'font-[pretendard-sb] text-[5vw]'}>
                설치가 완료되면 바탕화면에 생성된<br/>
                1%연산 아이콘을 더블 클릭하여 실행시킨다.
              </div>
            </div>
          </div>
        </div>
        <div className={'hidden sm:flex mt-[2.083vw] justify-center'}>
          <div className={'w-[62.5vw] h-[0.208vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'flex justify-center mt-[5.556vw] sm:mt-[2.083vw]'}>
          <img src={PcVersion6} alt={'pc_version_6'} className={'w-[91.111vw] sm:w-[62.5vw]'}/>
        </div>
        <div className={'flex sm:hidden mt-[11.667vw] justify-center'}>
          <div className={'w-[91.111vw] h-[0.556vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'mt-[11.667vw] sm:mt-[5.208vw] flex justify-center'}>
          <div className={'hidden sm:flex w-[62.5vw]'}>
            <div className={'w-[2.5vw] h-[2.5vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[1.823vw] text-[#ffffff] leading-none'}>
              5
            </div>
            <div className={'ml-[0.625vw]'}>
              <div className={'font-[pretendard-sb] text-[1.458vw]'}>
                실행시킨 1%연산의 로딩이 완료되면 [시작하기] 버튼을 누른다.
              </div>
              <div className={'font-[pretendard-l] text-[1.458vw]'}>
                (업데이트 항목이 있을 땐, 업데이트 버튼이 뜹니다.)
              </div>
            </div>
          </div>
          <div className={'block sm:hidden'}>
            <div className={'flex justify-center'}>
              <div className={'w-[13.333vw] h-[13.333vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[9.722vw] text-[#ffffff] leading-none'}>
                5
              </div>
            </div>
            <div className={'mt-[8.889vw] text-center'}>
              <div className={'font-[pretendard-sb] text-[5vw]'}>
                실행시킨 1%연산의 로딩이 완료되면<br/>
                [시작하기] 버튼을 누른다.
              </div>
              <div className={'font-[pretendard-l] text-[4.444vw]'}>
                (업데이트 항목이 있을 땐, 업데이트 버튼이 뜹니다.)
              </div>
            </div>
          </div>
        </div>
        <div className={'hidden sm:flex mt-[2.083vw] justify-center'}>
          <div className={'w-[62.5vw] h-[0.208vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'flex justify-center mt-[5.556vw] sm:mt-[2.083vw]'}>
          <img src={PcVersion7} alt={'pc_version_7'} className={'w-[91.111vw] sm:w-[62.5vw]'}/>
        </div>
        <div className={'flex sm:hidden mt-[11.667vw] justify-center'}>
          <div className={'w-[91.111vw] h-[0.556vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'mt-[11.667vw] sm:mt-[5.208vw] flex justify-center'}>
          <div className={'hidden sm:flex w-[62.5vw]'}>
            <div className={'w-[2.5vw] h-[2.5vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[1.823vw] text-[#ffffff] leading-none'}>
              6
            </div>
            <div className={'ml-[0.625vw]'}>
              <div className={'font-[pretendard-sb] text-[1.458vw]'}>
                1%연산에 로그인하여 학습을 즐긴다.
              </div>
            </div>
          </div>
          <div className={'block sm:hidden'}>
            <div className={'flex justify-center'}>
              <div className={'w-[13.333vw] h-[13.333vw] rounded-full bg-[#5c6dff] flex justify-center items-center font-[nanum-square-round-eb] text-[9.722vw] text-[#ffffff] leading-none'}>
                6
              </div>
            </div>
            <div className={'mt-[8.889vw] text-center'}>
              <div className={'font-[pretendard-sb] text-[5vw]'}>
                1%연산에 로그인하여 학습을 즐긴다.
              </div>
            </div>
          </div>
        </div>
        <div className={'hidden sm:flex mt-[2.083vw] justify-center'}>
          <div className={'w-[62.5vw] h-[0.208vw] bg-[#d9d9d9] rounded-full'}></div>
        </div>
        <div className={'flex justify-center mt-[5.556vw] sm:mt-[2.083vw] mb-[35.556vw] sm:mb-[12.5vw]'}>
          <img src={PcVersion8} alt={'pc_version_8'} className={'w-[91.111vw] sm:w-[62.5vw]'}/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default PcVersion