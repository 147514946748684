import { CSSTransition } from 'react-transition-group'
import { throttle } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './Content.css'
import Content1 from '../../resource/image/content_1.png'
import Content2 from '../../resource/image/content_2.png'
import Content3 from '../../resource/image/content_3.gif'
import Content4 from '../../resource/image/content_4.png'
import Content5 from '../../resource/image/content_5.png'
import Content6 from '../../resource/image/content_6.png'
import Content7 from '../../resource/image/content_7.gif'
import Content71 from '../../resource/image/content_7_1.gif'
import Content72 from '../../resource/image/content_7_2.gif'
import Content8 from '../../resource/image/content_8.gif'
import Content9 from '../../resource/image/content_9.gif'
import Content10 from '../../resource/image/content_10.gif'
import Content11 from '../../resource/image/content_11.png'
import Content12 from '../../resource/image/content_12.png'
import Content13 from '../../resource/image/content_13.gif'
import Content14 from '../../resource/image/content_14.png'
import Content15 from '../../resource/image/content_15.png'
import Content16 from '../../resource/image/content_16.png'
import Content17 from '../../resource/image/content_17.png'
import Content18 from '../../resource/image/content_18.png'
import Content19 from '../../resource/image/content_19.png'
import Trophy from '../../resource/image/trophy3.png'
import BalloonTrophy from '../../resource/image/balloon_trophy.png'
import PencilGogo from '../../resource/image/pencil_gogo.png'
import ContentBg1 from '../../resource/image/content_bg_1.png'
import GameStick from '../../resource/image/game_stick.png'
import BalloonNanu from '../../resource/image/balloon_nanu.png'
import MagnifierDdui from '../../resource/image/magnifier_ddui.png'
import MobileContentBg1 from '../../resource/image/mobile_content_bg_1.png'
import MobileLight from '../../resource/image/mobile_light.png'
import Footer from '../../component/Footer'
import ScrollToTopButton from '../../component/ScrollToTopButton'
import { useLocation } from 'react-router-dom'

const Content = () => {
  const location = useLocation()
  const [showContent1, setShowContent1] = useState(false)
  const [showContent2, setShowContent2] = useState(false)
  const [showContent3, setShowContent3] = useState(false)
  const [showContent4, setShowContent4] = useState(false)
  const [showContent5, setShowContent5] = useState(false)
  const content2 = useRef(null)
  const content3 = useRef(null)
  const content4 = useRef(null)
  const content5 = useRef(null)
  const contentTitle = ['오늘의 학습', '일프로도전', '학습영상']
  const content2Title = ['연산 게임', '아바타', '랭킹']
  const content3Title = ['학부모 앱', '학습 결과', '학습보고서']
  const contentDescription = ['매일 일정한 분량을 학습하여 학습 습관을 길러요.', '상위 1%에 도전하고 성공해 트로피를 수집해요.', '캐릭터와 함께 어려운 개념을 재미있고 쉽게 이해해요.']
  const mobileContentDescription = ['매일 일정한 분량을 학습하여\n학습 습관을 길러요.', '상위 1%에 도전하고 성공해\n트로피를 수집해요.', '캐릭터와 함께 어려운 개념을\n재미있고 쉽게 이해해요.']
  const content2Description = ['두뇌를 자극하는 다양한 연산 게임으로 재미와 실력을 한번에 잡아요.', '학습을 통해 획득한 보상으로 나만의 아바타를 꾸며요.', '승부욕을 자극하고 학습에 동기부여가 생겨요.']
  const mobileContent2Description = ['두뇌를 자극하는 다양한 연산 게임으로\n재미와 실력을 한번에 잡아요.', '학습을 통해 획득한 보상으로\n나만의 아바타를 꾸며요.', '승부욕을 자극하고\n학습에 동기부여가 생겨요.']
  const content3Description = ['앱을 설치하여 언제 어디서든 아이의 학습 데이터를 실시간으로 받아볼 수 있어요.', '어떤 문제를 얼만큼 어떻게 학습했는지 꼼꼼히 확인해요.', '매월 1일에 아이의 학습 데이터를 받아볼 수 있어요.']
  const mobileContent3Description = ['앱을 설치하여 언제 어디서든 아이의\n학습 데이터를 실시간으로 받아볼 수 있어요.', '어떤 문제를 얼만큼 어떻게\n학습했는지 꼼꼼히 확인해요.', '매월 1일에 아이의 학습 데이터를\n받아볼 수 있어요.']
  const [contentDescriptionIndex, setContentDescriptionIndex] = useState(0)
  const [mobileContentDescriptionIndex, setMobileContentDescriptionIndex] = useState(0)
  const [content2DescriptionIndex, setContent2DescriptionIndex] = useState(0)
  const [mobileContent2DescriptionIndex, setMobileContent2DescriptionIndex] = useState(0)
  const [content3DescriptionIndex, setContent3DescriptionIndex] = useState(0)
  const [mobileContent3DescriptionIndex, setMobileContent3DescriptionIndex] = useState(0)

  const controlScroll = () => {
    let halfInternalHeight = window.innerHeight / 1.5
    // const homeContentTwoOneScroll = homeContentTwoOne.current.getBoundingClientRect()
    const content2Scroll = content2.current.getBoundingClientRect()
    setShowContent2(content2Scroll.top <= halfInternalHeight)
    const content3Scroll = content3.current.getBoundingClientRect()
    setShowContent3(content3Scroll.top <= halfInternalHeight)
    const content4Scroll = content4.current.getBoundingClientRect()
    setShowContent4(content4Scroll.top <= halfInternalHeight)
    const content5Scroll = content5.current.getBoundingClientRect()
    setShowContent5(content5Scroll.top <= halfInternalHeight)
  }

  const handleScroll = throttle(controlScroll, 200)

  const handleResize = throttle(controlScroll, 200)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      // cleanup function
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => window.removeEventListener('resize', handleResize)
  }, []) // 빈 의존성 배열을 사용하여 마운트 시에만 이벤트 리스너를 추가

  useEffect(() => {
    setShowContent1(true)
  }, [])

  // useEffect(() => {
  //   window.scrollTo(0, 0) // 페이지 로드 시 스크롤을 최상단으로 이동
  // }, [])

  useEffect(() => {
    // url이 변경될 때마다 실행될 로직 ex) 뒤로 가기 버튼 클릭
    let pathname = location.pathname
    let pathComponents = pathname.split('/')

    // 필요없는 값 지우기.
    pathComponents = pathComponents.filter((el) => {
      return el !== null && el !== undefined && el !== ''
    })
    if (pathComponents[0] === 'content') {
      if (pathComponents[1] && content5.current) {
        const element = document.getElementById(pathComponents[1])
        element.scrollIntoView({ behavior: 'smooth' })
      } else {
        window.scrollTo(0, 0) // 페이지 로드 시 스크롤을 최상단으로 이동
      }
    } else {
      window.scrollTo(0, 0) // 페이지 로드 시 스크롤을 최상단으로 이동
    }
  }, [location])

  return (
    <div className={'w-[100vw] overflow-hidden'}>
      <div className={'block sm:hidden'}>
        <ScrollToTopButton/>
      </div>
      <div className={'hidden sm:block h-[73.021vw] relative'} ref={content2}>
        <div className={'absolute top-[13.75vw] left-[50%] -translate-x-1/2 text-center font-[nanum-square-round-eb] text-[2.188vw] leading-tight'}>
          <CSSTransition in={showContent1} timeout={700} classNames={'content-1-1'} mountOnEnter unmountOnExit>
            <div>
              1등은 다르다!<br/>
              스스로하는 마법같은 학습콘텐츠
            </div>
          </CSSTransition>
        </div>
        <div className={'w-full absolute top-[21.042vw]'}>
          <CSSTransition in={showContent1} timeout={900} classNames={'content-1-2'} mountOnEnter unmountOnExit>
            <div>
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                loop={true}
                pagination={{
                  clickable: true,
                  bulletClass: 'swiper-pagination-bullet custom-bullet-contentbymode', // 커스텀 클래스 추가
                  bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active-contentbymode', // 커스텀 클래스 추가
                  el: '.custom-swiper-pagination-contentbymode', // 커스텀 페이지네이션
                  renderBullet: (index, className) => {
                    return `<span class="${className}">${contentTitle[index]}</span>`
                  },
                }}
                // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                style={{ paddingTop: '9.323vw' }}
                onSlideChange={(swiper) => setContentDescriptionIndex(swiper.realIndex)}
              >
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-[51.042vw] h-[35.469vw]'}>
                    <div className={'flex justify-between'}>
                      <div>
                        <img src={Content1} alt={'content_1'} className={'w-[24.479vw]'}/>
                        <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>하루 10~15분 학습</div>
                      </div>
                      <div className={'w-[24.479vw]'}>
                        <img src={Content2} alt={'content_2'} className={'w-[24.479vw]'}/>
                        <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>한 단원 3회 반복학습</div>
                        <img src={Content3} alt={'content_3'} className={'w-[24.479vw] mt-[1.042vw]'}/>
                        <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>실력 향상</div>
                      </div>
                    </div>
                    <div className={'flex justify-center mt-[3.49vw]'}>
                      <a
                        target={'_blank'}
                        href={'https://1promath.notion.site/d6d94b7ea7ce4d959f1a763574a01492?pvs=4'}
                        className={'w-[19.792vw] h-[3.438vw] flex justify-center items-center border-[0.104vw] border-[#2046E4] rounded-full font-[nanum-square-round-eb] text-[#2046e4] text-[1.354vw] cursor-pointer'}>
                        더 자세히 보기
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-[51.042vw] h-[35.469vw]'}>
                    <div className={'flex justify-between'}>
                      <div>
                        <img src={Content4} alt={'content_4'} className={'w-[24.479vw]'}/>
                        <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>제한 시간 내 정확한 학습</div>
                      </div>
                      <div className={'w-[24.479vw]'}>
                        <img src={Content5} alt={'content_5'} className={'w-[24.479vw]'}/>
                        <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>트로피 수집</div>
                        <img src={Content6} alt={'content_6'} className={'w-[24.479vw] mt-[1.042vw]'}/>
                        <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>도전욕구 자극</div>
                      </div>
                    </div>
                    <div className={'flex justify-center mt-[3.49vw]'}>
                      <a
                        target={'_blank'}
                        href={'https://1promath.notion.site/07d569d28c2d4a1bb393ab0b85940e8c?pvs=4'}
                        className={'w-[19.792vw] h-[3.438vw] flex justify-center items-center border-[0.104vw] border-[#2046E4] rounded-full font-[nanum-square-round-eb] text-[#2046e4] text-[1.354vw] cursor-pointer'}>
                        더 자세히 보기
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-full h-[35.469vw]'}>
                    <div className={'flex justify-center'}>
                      <img src={Content7} alt={'content_7'} className={'h-[26.25vw] rounded-[0.521vw]'}/>
                    </div>
                    <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>학습영상존</div>
                    <div className={'flex justify-center mt-[3.49vw]'}>
                      <a
                        target={'_blank'}
                        href={'https://1promath.notion.site/1537396df9534c0da57755dea02301ae?pvs=4'}
                        className={'w-[19.792vw] h-[3.438vw] flex justify-center items-center border-[0.104vw] border-[#2046E4] rounded-full font-[nanum-square-round-eb] text-[#2046e4] text-[1.354vw] cursor-pointer'}>
                        더 자세히 보기
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <div className="custom-swiper-pagination-contentbymode"></div>
              </Swiper>
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[25.99vw] left-[50%] -translate-x-1/2 font-[pretendard-sb]'}>
          <CSSTransition in={showContent1} timeout={900} classNames={'content-1-2'} mountOnEnter unmountOnExit>
            <div className={'text-[1.458vw]'}>
              {contentDescription[contentDescriptionIndex]}
            </div>
          </CSSTransition>
        </div>
        <CSSTransition in={showContent1} timeout={1400} classNames={'content-1-3'} mountOnEnter unmountOnExit>
          <img src={Trophy} alt={'trophy'} className={'w-[20.625vw] absolute top-[19.063vw] right-[1.719vw]'}/>
        </CSSTransition>
        <div className={'w-[13.646vw] absolute top-[48.229vw] left-[6.667vw] z-[1]'} ref={content2}>
          <CSSTransition in={showContent2} timeout={1000} classNames={'content-2-1'} mountOnEnter unmountOnExit>
            <img src={PencilGogo} alt={'pencil_gogo'} className={'w-full'}/>
          </CSSTransition>
        </div>
      </div>
      <div className={'block sm:hidden w-full h-[420vw] relative'}>
        <CSSTransition in={showContent1} timeout={1400} classNames={'content-1-3'} mountOnEnter unmountOnExit>
          <img src={Trophy} alt={'trophy'} className={'w-[50vw] absolute top-[27.222vw] left-[71.667vw]'}/>
        </CSSTransition>
        <CSSTransition in={showContent1} timeout={700} classNames={'content-1-1'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[50vw] text-center font-[nanum-square-round-eb] text-[7.778vw] leading-tight'}>
            1등은 다르다!<br/>
            스스로하는 마법같은<br/>
            학습콘텐츠
          </div>
        </CSSTransition>
        <CSSTransition in={showContent1} timeout={900} classNames={'content-1-2'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[86.111vw]'}>
            <Swiper
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              loop={true}
              pagination={{
                clickable: true,
                bulletClass: 'swiper-pagination-bullet custom-bullet-contentbymode', // 커스텀 클래스 추가
                bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active-contentbymode', // 커스텀 클래스 추가
                el: '.custom-swiper-pagination-contentbymode', // 커스텀 페이지네이션
                renderBullet: (index, className) => {
                  return `<span class="${className}">${contentTitle[index]}</span>`
                },
              }}
              // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
              style={{ paddingTop: '42.778vw' }}
              onSlideChange={(swiper) => setMobileContentDescriptionIndex(swiper.realIndex)}
            >
              <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={'w-full h-[257.778vw] relative'}>
                  <div className={'w-full absolute top-0'}>
                    <div className={'flex justify-center'}>
                      <img src={Content1} alt={'content_1'} className={'w-[91.111vw]'}/>
                    </div>
                    <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>하루 10~15분 학습</div>
                  </div>
                  <div className={'w-full absolute top-[117.222vw]'}>
                    <div className={'flex justify-center'}>
                      <img src={Content2} alt={'content_2'} className={'w-[91.111vw]'}/>
                    </div>
                    <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>한 단원 3회 반복학습</div>
                  </div>
                  <div className={'w-full absolute top-[180.556vw]'}>
                    <div className={'flex justify-center'}>
                      <img src={Content3} alt={'content_3'} className={'w-[91.111vw]'}/>
                    </div>
                    <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>연산 실력 향상</div>
                  </div>
                  <div className={'w-full absolute top-[243.889vw] flex justify-center'}>
                    <a
                      target={'_blank'}
                      href={'https://1promath.notion.site/d6d94b7ea7ce4d959f1a763574a01492?pvs=4'}
                      className={'w-[91.111vw] h-[13.889vw] rounded-full border-[0.556vw] border-[#2046E4] flex justify-center items-center font-[nanum-square-round-eb] text-[5.556vw] text-[#2046E4] leading-none cursor-pointer'}>
                      더 자세히 보기
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={'w-full h-[257.778vw] relative'}>
                  <div className={'w-full absolute top-0'}>
                    <div className={'flex justify-center'}>
                      <img src={Content4} alt={'content_4'} className={'w-[91.111vw]'}/>
                    </div>
                    <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>제한 시간 내 정확한 학습</div>
                  </div>
                  <div className={'w-full absolute top-[117.222vw]'}>
                    <div className={'flex justify-center'}>
                      <img src={Content5} alt={'content_5'} className={'w-[91.111vw]'}/>
                    </div>
                    <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>트로피 수집</div>
                  </div>
                  <div className={'w-full absolute top-[180.556vw]'}>
                    <div className={'flex justify-center'}>
                      <img src={Content6} alt={'content_6'} className={'w-[91.111vw]'}/>
                    </div>
                    <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>도전욕구 자극</div>
                  </div>
                  <div className={'w-full absolute top-[243.889vw] flex justify-center'}>
                    <a
                      target={'_blank'}
                      href={'https://1promath.notion.site/07d569d28c2d4a1bb393ab0b85940e8c?pvs=4'}
                      className={'w-[91.111vw] h-[13.889vw] rounded-full border-[0.556vw] border-[#2046E4] flex justify-center items-center font-[nanum-square-round-eb] text-[5.556vw] text-[#2046E4] leading-none cursor-pointer'}>
                      더 자세히 보기
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={'w-full h-[257.778vw] relative'}>
                  <div className={'w-full absolute top-0'}>
                    <div className={'flex justify-center'}>
                      <img src={Content71} alt={'content_7_1'} className={'w-[91.111vw] rounded-[2.778vw]'}/>
                    </div>
                  </div>
                  <div className={'w-full absolute top-[70vw]'}>
                    <div className={'flex justify-center'}>
                      <img src={Content72} alt={'content_7_2'} className={'w-[91.111vw] rounded-[2.778vw]'}/>
                    </div>
                  </div>
                  <div className={'w-full absolute top-[140vw]'}>
                    <div className={'flex justify-center'}>
                      <img src={Content7} alt={'content_7'} className={'w-[91.111vw] rounded-[2.778vw]'}/>
                    </div>
                    <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>학습영상존</div>
                  </div>
                  <div className={'w-full absolute top-[243.889vw] flex justify-center'}>
                    <a
                      target={'_blank'}
                      href={'https://1promath.notion.site/1537396df9534c0da57755dea02301ae?pvs=4'}
                      className={'w-[91.111vw] h-[13.889vw] rounded-full border-[0.556vw] border-[#2046E4] flex justify-center items-center font-[nanum-square-round-eb] text-[5.556vw] text-[#2046E4] leading-none cursor-pointer'}>
                      더 자세히 보기
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <div className="custom-swiper-pagination-contentbymode"></div>
            </Swiper>
          </div>
        </CSSTransition>
        <CSSTransition in={showContent1} timeout={900} classNames={'content-1-2'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[105vw] font-[pretendard-sb] text-[5vw] whitespace-pre text-center leading-snug'}>
            {mobileContentDescription[mobileContentDescriptionIndex]}
          </div>
        </CSSTransition>
      </div>
      <div className={'relative h-[160vw] sm:h-[60vw]'} ref={content3}>
        <picture className={'relative'}>
          <source media="(min-width: 640px)" srcSet={ContentBg1}/>
          <source media="(max-width: 639px)" srcSet={MobileContentBg1}/>
          <img src={ContentBg1} alt={'content_bg_1'} className={'w-full'}/>
        </picture>
        <div className={'hidden sm:block'}>
          <div className={'absolute top-[4.167vw] left-[50%] -translate-x-1/2 text-center font-[nanum-square-round-eb] text-[2.188vw] leading-tight'}>
            <CSSTransition in={showContent3} timeout={700} classNames={'content-3-1'} mountOnEnter unmountOnExit>
              <div>
                학습에 즐거움을 더하는<br/>
                재미 요소
              </div>
            </CSSTransition>
          </div>
          <div className={'w-full absolute top-[11.458vw]'}>
            <CSSTransition in={showContent3} timeout={900} classNames={'content-3-2'} mountOnEnter unmountOnExit>
              <div>
                <Swiper
                  modules={[Pagination, Autoplay]}
                  slidesPerView={1}
                  loop={true}
                  pagination={{
                    clickable: true,
                    bulletClass: 'swiper-pagination-bullet custom-bullet-contentbymode', // 커스텀 클래스 추가
                    bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active-contentbymode', // 커스텀 클래스 추가
                    el: '.custom-swiper-pagination-contentbymode', // 커스텀 페이지네이션
                    renderBullet: (index, className) => {
                      return `<span class="${className}">${content2Title[index]}</span>`
                    },
                  }}
                  // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                  style={{ paddingTop: '9.323vw' }}
                  onSlideChange={(swiper) => setContent2DescriptionIndex(swiper.realIndex)}
                >
                  <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={'w-[52.083vw] h-[32.5vw]'}>
                      <div>
                        <div className={'flex justify-center'}>
                          <img src={Content8} alt={'content_8'} className={'h-[26.25vw] rounded-[0.521vw]'}/>
                        </div>
                        <div className={'w-full h-[3.333vw] flex justify-center items-center font-[pretendard-m] text-[1.25vw]'}>연산 속도 향상</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={'w-[52.083vw] h-[32.5vw]'}>
                      <div>
                        <div className={'flex justify-center'}>
                          <img src={Content9} alt={'content_9'} className={'h-[26.25vw] rounded-[0.521vw]'}/>
                        </div>
                        <div className={'w-full h-[3.333vw] flex justify-center items-center font-[pretendard-m] text-[1.25vw]'}>나만의 아바타</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={'w-[52.083vw] h-[32.5vw]'}>
                      <div>
                        <div className={'flex justify-center'}>
                          <img src={Content10} alt={'content_10'} className={'h-[26.25vw] rounded-[0.521vw]'}/>
                        </div>
                        <div className={'w-full h-[3.333vw] flex justify-center items-center font-[pretendard-m] text-[1.25vw]'}>학습량 랭킹</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <div className="custom-swiper-pagination-contentbymode"></div>
                </Swiper>
              </div>
            </CSSTransition>
          </div>
          <div className={'absolute top-[16.406vw] left-[50%] -translate-x-1/2 font-[pretendard-sb] text-[1.458vw]'}>
            <CSSTransition in={showContent3} timeout={900} classNames={'content-3-2'} mountOnEnter unmountOnExit>
              <div>
                {content2Description[content2DescriptionIndex]}
              </div>
            </CSSTransition>
          </div>
          <div className={'w-[14.583vw] absolute top-[3.229vw] left-[5.573vw]'}>
            <CSSTransition in={showContent3} timeout={1400} classNames={'content-3-3'} mountOnEnter unmountOnExit>
              <img src={BalloonTrophy} alt={'balloon_trophy'} className={'w-full'}/>
            </CSSTransition>
          </div>
          <div className={'w-[21.354vw] absolute top-[28.333vw] right-[1.042vw] z-[1]'} ref={content4}>
            <CSSTransition in={showContent4} timeout={1000} classNames={'content-4-1'} mountOnEnter unmountOnExit>
              <img src={BalloonNanu} alt={'balloon_nanu'} className={'w-full'}/>
            </CSSTransition>
          </div>
          <div className={'w-[10.104vw] absolute top-[45.156vw] left-[4.427vw] z-[1]'}>
            <CSSTransition in={showContent4} timeout={1000} classNames={'content-4-2'} mountOnEnter unmountOnExit>
              <img src={GameStick} alt={'game_stick'} className={'w-full'}/>
            </CSSTransition>
          </div>
        </div>
        <div className={'block sm:hidden'}>
          <CSSTransition in={showContent3} timeout={700} classNames={'content-3-1'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[13.889vw] text-center font-[nanum-square-round-eb] text-[7.778vw] leading-tight'}>
              학습에 즐거움을 더하는<br/>
              재미 요소
            </div>
          </CSSTransition>
          <CSSTransition in={showContent3} timeout={900} classNames={'content-3-2'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[40vw]'}>
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                loop={true}
                pagination={{
                  clickable: true,
                  bulletClass: 'swiper-pagination-bullet custom-bullet-contentbymode', // 커스텀 클래스 추가
                  bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active-contentbymode', // 커스텀 클래스 추가
                  el: '.custom-swiper-pagination-contentbymode', // 커스텀 페이지네이션
                  renderBullet: (index, className) => {
                    return `<span class="${className}">${content2Title[index]}</span>`
                  },
                }}
                // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                style={{ paddingTop: '42.778vw' }}
                onSlideChange={(swiper) => setMobileContent2DescriptionIndex(swiper.realIndex)}
              >
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-full h-[63.333vw] relative'}>
                    <div className={'w-full absolute top-0'}>
                      <div className={'flex justify-center'}>
                        <img src={Content8} alt={'content_8'} className={'w-[91.111vw] rounded-[2.778vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>연산 속도 향상</div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-full h-[63.333vw] relative'}>
                    <div className={'w-full absolute top-0'}>
                      <div className={'flex justify-center'}>
                        <img src={Content9} alt={'content_9'} className={'w-[91.111vw] rounded-[2.778vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>아바타 꾸미기</div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-full h-[63.333vw] relative'}>
                    <div className={'w-full absolute top-0'}>
                      <div className={'flex justify-center'}>
                        <img src={Content10} alt={'content_10'} className={'w-[91.111vw] rounded-[2.778vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>학습량 랭킹</div>
                    </div>
                  </div>
                </SwiperSlide>
                <div className="custom-swiper-pagination-contentbymode"></div>
              </Swiper>
            </div>
          </CSSTransition>
          <CSSTransition in={showContent3} timeout={900} classNames={'content-3-2'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[58.889vw] font-[pretendard-sb] text-[5vw] whitespace-pre text-center leading-snug'}>
              {mobileContent2Description[mobileContent2DescriptionIndex]}
            </div>
          </CSSTransition>
          <CSSTransition in={showContent3} timeout={1400} classNames={'content-3-4'} mountOnEnter unmountOnExit>
            <img src={BalloonNanu} alt={'balloon_nanu'} className={'w-[29.444vw] absolute bottom-[-11.667vw] right-[2.5vw] z-[1]'}/>
          </CSSTransition>
          <CSSTransition in={showContent3} timeout={1400} classNames={'content-3-3'} mountOnEnter unmountOnExit>
            <img src={GameStick} alt={'game_stick'} className={'w-[19.167vw] absolute top-[-10.278vw] left-[6.111vw]'}/>
          </CSSTransition>
        </div>
      </div>
      <div className={'relative h-[395.556vw] sm:h-[65.313vw]'} ref={content5} id="content5">
        <div className={'hidden sm:block'}>
          <div className={'absolute top-[7.187vw] left-[50%] -translate-x-1/2 text-center font-[nanum-square-round-eb] text-[2.188vw] leading-tight'}>
            <CSSTransition in={showContent5} timeout={700} classNames={'content-5-1'} mountOnEnter unmountOnExit>
              <div>
                아이를 잘 아는<br/>
                AI 초밀착 학습 관리
              </div>
            </CSSTransition>
          </div>
          <div className={'w-full absolute top-[14.479vw]'}>
            <CSSTransition in={showContent5} timeout={900} classNames={'content-5-2'} mountOnEnter unmountOnExit>
              <div>
                <Swiper
                  modules={[Pagination, Autoplay]}
                  slidesPerView={1}
                  loop={true}
                  pagination={{
                    clickable: true,
                    bulletClass: 'swiper-pagination-bullet custom-bullet-contentbymode', // 커스텀 클래스 추가
                    bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active-contentbymode', // 커스텀 클래스 추가
                    el: '.custom-swiper-pagination-contentbymode', // 커스텀 페이지네이션
                    renderBullet: (index, className) => {
                      return `<span class="${className}">${content3Title[index]}</span>`
                    },
                  }}
                  // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                  style={{ paddingTop: '9.323vw' }}
                  onSlideChange={(swiper) => setContent3DescriptionIndex(swiper.realIndex)}
                >
                  <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={'w-[51.042vw] h-[35.469vw]'}>
                      <div className={'flex justify-between'}>
                        <div>
                          <img src={Content11} alt={'content_11'} className={'w-[24.479vw]'}/>
                          <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>일일 학습 결과</div>
                        </div>
                        <div className={'w-[24.479vw]'}>
                          <img src={Content12} alt={'content_12'} className={'w-[24.479vw]'}/>
                          <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>우편함</div>
                          <img src={Content13} alt={'content_13'} className={'w-[24.479vw] mt-[1.042vw]'}/>
                          <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>출석</div>
                        </div>
                      </div>
                      <div className={'flex justify-center mt-[3.49vw]'}>
                        <a
                          target={'_blank'}
                          href={'https://1promath.notion.site/adc541888f7b41b19b49eb8c8b8b663c?pvs=4'}
                          className={'w-[19.792vw] h-[3.438vw] flex justify-center items-center border-[0.104vw] border-[#2046E4] rounded-full font-[nanum-square-round-eb] text-[#2046e4] text-[1.354vw] cursor-pointer'}>
                          더 자세히 보기
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={'w-[51.042vw] h-[35.469vw]'}>
                      <div className={'flex justify-between'}>
                        <div>
                          <img src={Content14} alt={'content_14'} className={'w-[24.479vw]'}/>
                          <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>오답 노트</div>
                        </div>
                        <div className={'w-[24.479vw]'}>
                          <img src={Content15} alt={'content_15'} className={'w-[24.479vw]'}/>
                          <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>일일 학습 결과</div>
                          <img src={Content16} alt={'content_16'} className={'w-[24.479vw] mt-[1.042vw]'}/>
                          <div className={'font-[pretendard-m] text-[1.25vw] text-center leading-none mt-[1.042vw]'}>주간 학습 결과</div>
                        </div>
                      </div>
                      <div className={'flex justify-center mt-[3.49vw]'}>
                        <a
                          target={'_blank'}
                          href={'https://1promath.notion.site/d31e933b7b8449fb8c4f59b3c8d781d9?pvs=4'}
                          className={'w-[19.792vw] h-[3.438vw] flex justify-center items-center border-[0.104vw] border-[#2046E4] rounded-full font-[nanum-square-round-eb] text-[#2046e4] text-[1.354vw] cursor-pointer'}>
                          더 자세히 보기
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={'w-[51.042vw] h-[29.583vw] flex justify-between'}>
                      <div>
                        <div className={'flex justify-center'}>
                          <img src={Content17} alt={'content_17'} className={'w-[24.479vw] h-[26.042vw] rounded-[0.521vw]'}/>
                        </div>
                        <div className={'w-full h-[3.333vw] flex justify-center items-center font-[pretendard-m] text-[1.25vw]'}>학습 현황</div>
                      </div>
                      <div>
                        <div className={'flex justify-center'}>
                          <img src={Content18} alt={'content_18'} className={'w-[24.479vw] h-[26.042vw] rounded-[0.521vw]'}/>
                        </div>
                        <div className={'w-full h-[3.333vw] flex justify-center items-center font-[pretendard-m] text-[1.25vw]'}>우수/취약점 분석</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <div className="custom-swiper-pagination-contentbymode"></div>
                </Swiper>
              </div>
            </CSSTransition>
          </div>
          <div className={'w-[24.948vw] absolute top-[9.271vw] right-0 z-[1]'}>
            <CSSTransition in={showContent5} timeout={1400} classNames={'content-5-3'} mountOnEnter unmountOnExit>
              <img src={Content19} alt={'content_19'} className={'w-full'}/>
            </CSSTransition>
          </div>
          <CSSTransition in={showContent5} timeout={1400} classNames={'content-5-5'} mountOnEnter unmountOnExit>
            <img src={MagnifierDdui} alt={'magnifier_ddui'} className={'w-[20.729vw] absolute top-[32.865vw] left-[2.083vw] z-[1]'}/>
          </CSSTransition>
          <div className={'absolute top-[19.427vw] left-[50%] -translate-x-1/2 font-[pretendard-sb]'}>
            <CSSTransition in={showContent5} timeout={900} classNames={'content-5-2'} mountOnEnter unmountOnExit>
              <div className={'text-[1.458vw]'}>
                {content3Description[content3DescriptionIndex]}
              </div>
            </CSSTransition>
          </div>
        </div>
        <div className={'block sm:hidden'}>
          <CSSTransition in={showContent5} timeout={700} classNames={'content-5-1'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[30.556vw] text-center font-[nanum-square-round-eb] text-[7.778vw] leading-tight'}>
              아이를 잘 아는<br/>
              AI 초밀착 학습 관리
            </div>
          </CSSTransition>
          <CSSTransition in={showContent5} timeout={900} classNames={'content-5-2'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[56.667vw]'}>
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                loop={true}
                pagination={{
                  clickable: true,
                  bulletClass: 'swiper-pagination-bullet custom-bullet-contentbymode', // 커스텀 클래스 추가
                  bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active-contentbymode', // 커스텀 클래스 추가
                  el: '.custom-swiper-pagination-contentbymode', // 커스텀 페이지네이션
                  renderBullet: (index, className) => {
                    return `<span class="${className}">${content3Title[index]}</span>`
                  },
                }}
                // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                style={{ paddingTop: '42.778vw' }}
                onSlideChange={(swiper) => setMobileContent3DescriptionIndex(swiper.realIndex)}
              >
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-full h-[257.778vw] relative'}>
                    <div className={'w-full absolute top-0'}>
                      <div className={'flex justify-center'}>
                        <img src={Content11} alt={'content_11'} className={'w-[91.111vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>일일 학습 결과</div>
                    </div>
                    <div className={'w-full absolute top-[117.222vw]'}>
                      <div className={'flex justify-center'}>
                        <img src={Content12} alt={'content_12'} className={'w-[91.111vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>메시지함</div>
                    </div>
                    <div className={'w-full absolute top-[180.556vw]'}>
                      <div className={'flex justify-center'}>
                        <img src={Content13} alt={'content_13'} className={'w-[91.111vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>출석</div>
                    </div>
                    <div className={'w-full absolute top-[243.889vw] flex justify-center'}>
                      <a
                        target={'_blank'}
                        href={'https://1promath.notion.site/adc541888f7b41b19b49eb8c8b8b663c?pvs=4'}
                        className={'w-[91.111vw] h-[13.889vw] rounded-full border-[0.556vw] border-[#2046E4] flex justify-center items-center font-[nanum-square-round-eb] text-[5.556vw] text-[#2046E4] leading-none cursor-pointer'}>
                        더 자세히 보기
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-full h-[257.778vw] relative'}>
                    <div className={'w-full absolute top-0'}>
                      <div className={'flex justify-center'}>
                        <img src={Content14} alt={'content_14'} className={'w-[91.111vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>오답 노트</div>
                    </div>
                    <div className={'w-full absolute top-[117.222vw]'}>
                      <div className={'flex justify-center'}>
                        <img src={Content15} alt={'content_15'} className={'w-[91.111vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>일일 학습 결과</div>
                    </div>
                    <div className={'w-full absolute top-[180.556vw]'}>
                      <div className={'flex justify-center'}>
                        <img src={Content16} alt={'content_16'} className={'w-[91.111vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>주간 학습 결과</div>
                    </div>
                    <div className={'w-full absolute top-[243.889vw] flex justify-center'}>
                      <a
                        target={'_blank'}
                        href={'https://1promath.notion.site/d31e933b7b8449fb8c4f59b3c8d781d9?pvs=4'}
                        className={'w-[91.111vw] h-[13.889vw] rounded-full border-[0.556vw] border-[#2046E4] flex justify-center items-center font-[nanum-square-round-eb] text-[5.556vw] text-[#2046E4] leading-none cursor-pointer'}>
                        더 자세히 보기
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'w-full h-[257.778vw] relative'}>
                    <div className={'w-full absolute top-0'}>
                      <div className={'flex justify-center'}>
                        <img src={Content17} alt={'content_17'} className={'w-[91.111vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>학습 현황</div>
                    </div>
                    <div className={'w-full absolute top-[117.222vw]'}>
                      <div className={'flex justify-center'}>
                        <img src={Content18} alt={'content_18'} className={'w-[91.111vw]'}/>
                      </div>
                      <div className={'w-full mt-[4.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>우수/취약점 분석</div>
                    </div>
                  </div>
                </SwiperSlide>
                <div className="custom-swiper-pagination-contentbymode"></div>
              </Swiper>
            </div>
          </CSSTransition>
          <CSSTransition in={showContent5} timeout={1400} classNames={'content-5-4'} mountOnEnter unmountOnExit>
            <img src={MobileLight} alt={'mobile_light'} className={'w-[23.889vw] absolute top-[10.278vw] left-0'}/>
          </CSSTransition>
          <CSSTransition in={showContent5} timeout={900} classNames={'content-5-2'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[75.556vw] font-[pretendard-sb] text-[5vw] whitespace-pre text-center leading-snug'}>
              {mobileContent3Description[mobileContent3DescriptionIndex]}
            </div>
          </CSSTransition>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Content