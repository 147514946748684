import { ReactComponent as Up } from '../resource/image/up.svg'

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className="fixed z-[1000] right-[7.5vw] sm:right-[2.604vw] bottom-[7.5vw] sm:bottom-[2.604vw]">
      <div>
        <div
          className={'flex justify-center items-center bg-[#ffffff] rounded-full shadow-[0_0_1.111vw_0_rgba(0,0,0,0.12)] cursor-pointer select-none w-[15vw] sm:w-[3.333vw] h-[15vw] sm:h-[3.333vw]'}
          onClick={scrollToTop}
        >
          <Up className={'w-[6.667vw] sm:w-[1.563vw] h-auto'}/>
        </div>
      </div>
    </div>
  )
}

export default ScrollToTopButton
