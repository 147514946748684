import { CSSTransition } from 'react-transition-group'
import { useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import curriculum1 from '../../resource/image/curriculum_1.png'
import './Curriculum.css'
import ResearcherJeong from '../../resource/image/researcher_jeong.png'
import Arithmetic from '../../resource/image/arithmetic.png'
import Books from '../../resource/image/books2.png'
import LevelMedal from '../../resource/image/level_medal.png'
import Step from '../../resource/image/step.png'
import Curriculum21 from '../../resource/image/curriculum_2_1.png'
import Curriculum22 from '../../resource/image/curriculum_2_2.png'
import Curriculum23 from '../../resource/image/curriculum_2_3.png'
import Curriculum24 from '../../resource/image/curriculum_2_4.png'
import Curriculum25 from '../../resource/image/curriculum_2_5.png'
import Curriculum3 from '../../resource/image/curriculum_3.png'
import BalloonGogo from '../../resource/image/balloon_gogo.png'
import BalloonBero from '../../resource/image/balloon_bero.png'
import Trophy from '../../resource/image/trophy2.png'
import Shining from '../../resource/image/shining.png'
import StepLine from '../../resource/image/step_ling.png'
import MobileStepLine from '../../resource/image/mobile_step_line.png'
import CurriculumBg1 from '../../resource/image/curriculum_bg_1.png'
import CurriculumMobileBg1 from '../../resource/image/curriculum_mobile_bg_1.png'
import CurriculumBg2 from '../../resource/image/curriculum_bg_2.png'
import CurriculumMobileBg2 from '../../resource/image/curriculum_mobile_bg_2.png'
import mobileGraduationCapNBook from '../../resource/image/mobile_graduation_cap_and_book.png'
import mobileMrJung from '../../resource/image/mobile_mr_jung.png'
import mobileArithmetic from '../../resource/image/mobile_arithmetic.png'
import mobileBooks from '../../resource/image/mobile_books.png'
import mobileShining from '../../resource/image/mobile_shining.png'
import Footer from '../../component/Footer'
import ScrollToTopButton from '../../component/ScrollToTopButton'

const Curriculum = () => {
  const [showCurriculumContent1, setShowCurriculumContent1] = useState(false)
  const [showCurriculumContent2, setShowCurriculumContent2] = useState(false)
  const [showCurriculumContent3, setShowCurriculumContent3] = useState(false)
  const [showCurriculumContent31, setShowCurriculumContent31] = useState(false)
  const [showCurriculumContent32, setShowCurriculumContent32] = useState(false)
  const [showCurriculumContent33, setShowCurriculumContent33] = useState(false)
  const [showCurriculumContent34, setShowCurriculumContent34] = useState(false)
  const [showCurriculumContent35, setShowCurriculumContent35] = useState(false)
  const [showCurriculumContent36, setShowCurriculumContent36] = useState(false)
  const [showCurriculumContent8, setShowCurriculumContent8] = useState(false)
  const curriculumContent2 = useRef(null)
  const curriculumContent3 = useRef(null)
  const curriculumContent31 = useRef(null)
  const curriculumContent32 = useRef(null)
  const curriculumContent33 = useRef(null)
  const curriculumContent34 = useRef(null)
  const curriculumContent35 = useRef(null)
  const curriculumContent36 = useRef(null)
  const curriculumContent8 = useRef(null)
  const [showMobileCurriculumContent2, setShowMobileCurriculumContent2] = useState(false)
  const [showMobileCurriculumContent3, setShowMobileCurriculumContent3] = useState(false)
  const [showMobileCurriculumContent4, setShowMobileCurriculumContent4] = useState(false)
  const mobileCurriculumContent2 = useRef(null)
  const mobileCurriculumContent3 = useRef(null)
  const mobileCurriculumContent4 = useRef(null)

  const controlScroll = () => {
    let halfInternalHeight = window.innerHeight / 1.5
    // const homeContentTwoOneScroll = homeContentTwoOne.current.getBoundingClientRect()
    const curriculum2Scroll = curriculumContent2.current.getBoundingClientRect()
    setShowCurriculumContent2(curriculum2Scroll.top <= halfInternalHeight)
    const curriculum3Scroll = curriculumContent3.current.getBoundingClientRect()
    setShowCurriculumContent3(curriculum3Scroll.top <= halfInternalHeight)
    const curriculum31Scroll = curriculumContent31.current.getBoundingClientRect()
    setShowCurriculumContent31(curriculum31Scroll.top <= halfInternalHeight)
    const curriculum32Scroll = curriculumContent32.current.getBoundingClientRect()
    setShowCurriculumContent32(curriculum32Scroll.top <= halfInternalHeight)
    const curriculum33Scroll = curriculumContent33.current.getBoundingClientRect()
    setShowCurriculumContent33(curriculum33Scroll.top <= halfInternalHeight)
    const curriculum34Scroll = curriculumContent34.current.getBoundingClientRect()
    setShowCurriculumContent34(curriculum34Scroll.top <= halfInternalHeight)
    const curriculum35Scroll = curriculumContent35.current.getBoundingClientRect()
    setShowCurriculumContent35(curriculum35Scroll.top <= halfInternalHeight)
    const curriculum36Scroll = curriculumContent36.current.getBoundingClientRect()
    setShowCurriculumContent36(curriculum36Scroll.top <= halfInternalHeight)
    const curriculum8Scroll = curriculumContent8.current.getBoundingClientRect()
    setShowCurriculumContent8(curriculum8Scroll.top <= halfInternalHeight)
    const mobileCurriculum2Scroll = mobileCurriculumContent2.current.getBoundingClientRect()
    setShowMobileCurriculumContent2(mobileCurriculum2Scroll.top <= halfInternalHeight)
    const mobileCurriculum3Scroll = mobileCurriculumContent3.current.getBoundingClientRect()
    setShowMobileCurriculumContent3(mobileCurriculum3Scroll.top <= halfInternalHeight)
    const mobileCurriculum4Scroll = mobileCurriculumContent4.current.getBoundingClientRect()
    setShowMobileCurriculumContent4(mobileCurriculum4Scroll.top <= halfInternalHeight)
  }

  const handleScroll = throttle(controlScroll, 200)

  const handleResize = throttle(controlScroll, 200)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      // cleanup function
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => window.removeEventListener('resize', handleResize)
  }, []) // 빈 의존성 배열을 사용하여 마운트 시에만 이벤트 리스너를 추가

  useEffect(() => {
    setShowCurriculumContent1(true)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0) // 페이지 로드 시 스크롤을 최상단으로 이동
  }, [])

  return (
    <div className={'w-[100vw] overflow-hidden bg-[#F8F8F8]'}>
      <div className={'block sm:hidden'}>
        <ScrollToTopButton/>
      </div>
      <div className={'hidden sm:block w-full h-[86.146vw] relative'}>
        <CSSTransition in={showCurriculumContent1} timeout={700} classNames={'curriculum-content-1-1'} mountOnEnter unmountOnExit>
          <img src={curriculum1} alt={'curriculum_1'} className={'absolute top-[13.75vw] left-[50%] -translate-x-1/2 w-[8.542vw]'}/>
        </CSSTransition>
        <CSSTransition in={showCurriculumContent1} timeout={700} classNames={'curriculum-content-1-1'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[23.958vw] flex justify-center font-[nanum-square-round-eb] text-[2.604vw] leading-none'}>일프로연산의&nbsp;<span className={'text-[#2046e4]'}>1등 커리큘럼</span>
          </div>
        </CSSTransition>
        <CSSTransition in={showCurriculumContent1} timeout={900} classNames={'curriculum-content-1-2'} mountOnEnter unmountOnExit>
          <div className={'w-[62.5vw] h-[16.823vw] absolute top-[33.229vw] left-[50%] -translate-x-1/2 bg-[#ffffff] rounded-[1.563vw] shadow-[10px_20px_50px_0_rgba(0,0,0,0.05)]'}>
            <div className={'absolute top-[3.333vw] left-[3.75vw] font-[nanum-square-round-eb] text-[1.979vw]'}>
              <span className={'text-[#2046E4]'}>12년 </span>연구 개발
            </div>
            <div className={'w-[34.583vw] absolute top-[6.563vw] left-[3.75vw] font-[pretendard-m] text-[1.25vw] leading-snug'}>
              일프로연산은 게임처럼 연산 콘텐츠를 제작하여 아이들이 쉽고 재미있게 수학 개념을 익힐 수 있도록 설계 되었습니다.
            </div>
            <div className={'w-[34.583vw] absolute top-[10.833vw] left-[3.75vw] font-[pretendard-m] text-[1.25vw] leading-none'}>
              [정대욱 수석 연구원]
            </div>
            <div className={'absolute top-[12.708vw] left-[3.75vw] font-[nanum-square-round-r] text-[0.781vw] text-[#AEAEAE] leading-none'}>
              서울대학교 전기공학부 졸업 / 광운대학교 인공지능 공학 박사 / (現)강남 대치동 수학전문 학원 원장
            </div>
            <img src={ResearcherJeong} alt={'researcher_jeong'} className={'w-[14.115vw] absolute bottom-0 right-[3.958vw]'}/>
          </div>
        </CSSTransition>
        <CSSTransition in={showCurriculumContent1} timeout={1100} classNames={'curriculum-content-1-3'} mountOnEnter unmountOnExit>
          <div className={'w-[30.625vw] h-[22.083vw] absolute top-[51.563vw] left-[18.75vw] bg-[#ffffff] rounded-[1.563vw] shadow-[10px_20px_50px_0_rgba(0,0,0,0.05)]'}>
            <img src={Arithmetic} alt={'arithmetic'} className={'w-[7.5vw] absolute top-[2.708vw] left-[4.375vw]'}/>
            <div className={'absolute top-[11.458vw] left-[4.375vw] font-[nanum-square-round-eb] text-[1.563vw] leading-none'}>
              다양한 연산 유형 탑재
            </div>
            <div className={'w-[20.833vw] absolute top-[14.271vw] left-[4.375vw] font-[pretendard-m] text-[1.25vw]'}>
              기본적인 가로셈, 세로셈, 자주 어려워 하는 빈칸 채우기, 크기 비교하기 등 다수의 심화 학습 유형을 담고 있습니다.
            </div>
          </div>
        </CSSTransition>
        <CSSTransition in={showCurriculumContent1} timeout={1100} classNames={'curriculum-content-1-3'} mountOnEnter unmountOnExit>
          <div className={'w-[30.625vw] h-[22.083vw] absolute top-[51.563vw] left-[50.625vw] bg-[#ffffff] rounded-[1.563vw] shadow-[10px_20px_50px_0_rgba(0,0,0,0.05)]'}>
            <img src={Books} alt={'books'} className={'absolute top-[2.708vw] left-[4.375vw] w-[7.5vw]'}/>
            <div className={'absolute top-[11.458vw] left-[4.375vw] font-[nanum-square-round-eb] text-[1.563vw] leading-none'}>
              정규 교과 과정 100% 반영
            </div>
            <div className={'w-[20.833vw] absolute top-[14.271vw] left-[4.375vw] font-[pretendard-m] text-[1.25vw]'}>
              초등수학 정규 교과 과정의 연산파트를<br/>
              모두 모아 놓은 연산문제집이므로 교과<br/>
              학습과의 100% 연계가 가능합니다.
            </div>
          </div>
        </CSSTransition>
      </div>
      <div className={'block sm:hidden h-[577.778vw] relative'}>
        <div className={'w-full h-[143.333vw] relative'}>
          <CSSTransition in={showCurriculumContent1} timeout={700} classNames={'curriculum-content-1-1'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[36.111vw] left-[27.222vw]'}>
              <div className={'flex justify-center'}>
                <img src={mobileGraduationCapNBook} alt={'mobile_graduation_cap_and_book'} className={'w-[43.333vw]'}/>
              </div>
              <div className={'font-[nanum-square-round-eb] text-[8.333vw] mt-[8.889vw] text-center'}>
                일프로연산의<br/>
                <span className={'text-[#2046E4]'}>1등 커리큘럼</span>
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'w-full h-[186.667vw] relative'} ref={mobileCurriculumContent2}>
          <CSSTransition in={showMobileCurriculumContent2} timeout={700} classNames={'mobile-curriculum-content-2-1'} mountOnEnter unmountOnExit>
            <div className={'w-[91.111vw] h-[177.778vw] absolute top-0 left-[50%] -translate-x-1/2 bg-[#ffffff] rounded-[8.333vw] shadow-[10px_20px_50px_0_rgba(0,0,0,0.05)]'}>
              <div className={'w-full absolute top-[17.778vw] flex justify-center font-[nanum-square-round-eb] text-[8.333vw] leading-none'}>
                <span className={'text-[#2046E4]'}>12년</span>&nbsp;연구 개발
              </div>
              <div className={'w-full absolute top-[35vw] font-[pretendard-m] text-[5vw] text-center'}>
                일프로연산은 게임처럼 연산 콘텐츠를<br/>
                제작하여 아이들이 쉽고 재미있게 수학<br/>
                개념을 익힐 수 있도록 설계 되었습니다.
              </div>
              <div className={'w-full absolute top-[64.444vw] font-[pretendard-m] text-[5vw] text-center leading-none'}>
                [정대욱 수석 연구원]
              </div>
              <div className={'w-full absolute top-[75.556vw] font-[pretendard-r] text-[4.167vw] text-[#AEAEAE] text-center'}>
                서울대학교 전기공학부 졸업<br/>
                광운대학교 인공지능 공학 박사<br/>
                (現)강남 대치동 수학전문 학원 원장
              </div>
              <img src={mobileMrJung} alt={'mobile_mr_jung'} className={'w-[75.556vw] absolute bottom-0 left-[50%] -translate-x-1/2'}/>
            </div>
          </CSSTransition>
        </div>
        <div className={'w-full h-[110.556vw] relative'} ref={mobileCurriculumContent3}>
          <CSSTransition in={showMobileCurriculumContent3} timeout={700} classNames={'mobile-curriculum-content-3-1'} mountOnEnter unmountOnExit>
            <div className={'w-[91.111vw] h-[101.667vw] absolute top-0 left-[50%] -translate-x-1/2 bg-[#ffffff] rounded-[8.333vw] shadow-[10px_20px_50px_0_rgba(0,0,0,0.05)]'}>
              <img src={mobileArithmetic} alt={'mobile_arithmetic'} className={'w-[33.333vw] absolute top-[10vw] left-[8.611vw]'}/>
              <div className={'w-full absolute top-[50vw] left-[8.611vw] font-[nanum-square-round-eb] text-[6.667vw]'}>
                다양한 연산 유형 탑재
              </div>
              <div className={'w-[73.333vw] absolute top-[63.333vw] left-[8.611vw] font-[pretendard-m] text-[5vw]'}>
                기본적인 가로셈,세로셈은 물론 많이<br/>
                어려워 하는 빈칸 채우기, 크기 비교하<br/>
                기 등 다수의 심화 학습 유형을 담고<br/>
                있습니다.
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'w-full h-[110.556vw] relative'} ref={mobileCurriculumContent4}>
          <CSSTransition in={showMobileCurriculumContent4} timeout={700} classNames={'mobile-curriculum-content-4-1'} mountOnEnter unmountOnExit>
            <div className={'w-[91.111vw] h-[101.667vw] absolute top-0 left-[50%] -translate-x-1/2 bg-[#ffffff] rounded-[8.333vw] shadow-[10px_20px_50px_0_rgba(0,0,0,0.05)]'}>
              <img src={mobileBooks} alt={'mobile_books'} className={'w-[33.333vw] absolute top-[10vw] left-[8.611vw]'}/>
              <div className={'w-full absolute top-[50vw] left-[8.611vw] font-[nanum-square-round-eb] text-[6.667vw]'}>
                정규 교과 과정 100%반영
              </div>
              <div className={'w-[73.333vw] absolute top-[63.333vw] left-[8.611vw] font-[pretendard-m] text-[5vw]'}>
                초등수학 정규 교과 과정의 연산파트를 모두 모아 놓은 연산문제집이므로 교과 학습과의 100% 연계가 가능합니다.
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
      <div className={'w-full h-[136.667vw] sm:h-[33.75vw] relative'} ref={curriculumContent2}>
        <CSSTransition in={showCurriculumContent2} timeout={700} classNames={'curriculum-content-2-1'} mountOnEnter unmountOnExit>
          <img src={LevelMedal} alt={'level_medal'} className={'w-[27.778vw] sm:w-[6.25vw] absolute top-0 left-[14.444vw] sm:left-[18.75vw]'}/>
        </CSSTransition>
        <CSSTransition in={showCurriculumContent2} timeout={700} classNames={'curriculum-content-2-1'} mountOnEnter unmountOnExit>
          <div>
            <div className={'hidden sm:block absolute top-[1.823vw] left-[26.667vw] font-[nanum-square-round-eb] text-[2.604vw] leading-none'}>
              일프로연산 레벨/학년
            </div>
            <div className={'block sm:hidden absolute top-[4.167vw] left-[46.667vw] font-[nanum-square-round-eb] text-[8.333vw] leading-snug'}>
              일프로연산<br/>
              레벨/학년
            </div>
          </div>
        </CSSTransition>
        <CSSTransition in={showCurriculumContent2} timeout={900} classNames={'curriculum-content-2-2'} mountOnEnter unmountOnExit>
          <div className={'absolute top-[45.556vw] sm:top-[12.865vw] left-[4.444vw] sm:left-[18.75vw] flex justify-center'}>
            <div className={'hidden sm:block'}>
              <div className={'w-[62.5vw] h-[3.75vw] pl-[4.063vw] flex items-center bg-[#C3D6FF] rounded-[1.042vw]'}>
                <div className={'w-[7.083vw] h-[2.083vw] flex justify-center items-center font-[pretendard-m] text-[1.25vw] text-[#ffffff] bg-[#5c6dff] rounded-full'}>
                  일프로연산
                </div>
                <div className={'w-[46.146vw] flex justify-between font-[pretendard-m] text-[1.25vw] ml-[1.458vw]'}>
                  <div className={'w-[5.417vw]'}>1~2레벨</div>
                  <div className={'w-[5.417vw]'}>3~4레벨</div>
                  <div className={'w-[5.417vw]'}>5~6레벨</div>
                  <div className={'w-[5.417vw]'}>7~8레벨</div>
                  <div className={'w-[5.417vw]'}>9~10레벨</div>
                  <div className={'w-[5.417vw]'}>11~12레벨</div>
                </div>
              </div>
              <div className={'w-[62.5vw] h-[3.75vw] pl-[4.063vw] flex items-center bg-[#ffffff] rounded-[1.042vw] mt-[1.042vw]'}>
                <div className={'w-[7.083vw] h-[2.083vw] flex justify-center items-center font-[pretendard-m] text-[1.25vw] text-[#000000] bg-[#ffffff] rounded-full'}>
                  정규교과
                </div>
                <div className={'w-[46.146vw] flex justify-between font-[pretendard-m] text-[1.25vw] ml-[1.458vw]'}>
                  <div className={'w-[5.417vw]'}>1학년</div>
                  <div className={'w-[5.417vw]'}>2학년</div>
                  <div className={'w-[5.417vw]'}>3학년</div>
                  <div className={'w-[5.417vw]'}>4학년</div>
                  <div className={'w-[5.417vw]'}>5학년</div>
                  <div className={'w-[5.417vw]'}>6학년</div>
                </div>
              </div>
            </div>
            <div className={'block sm:hidden'}>
              <div className={'w-[91.111vw] h-[55.556vw] overflow-scroll overflow-x-scroll'}>
                <div className={'w-[276.667vw] h-[20vw] pl-[4.063vw] flex items-center bg-[#C3D6FF] rounded-[4.167vw]'}>
                  <div className={'w-[37.778vw] h-[11.111vw] flex justify-center items-center font-[pretendard-m] text-[6.667vw] text-[#ffffff] bg-[#5c6dff] rounded-full'}>
                    일프로연산
                  </div>
                  <div className={'w-[217.778vw] flex justify-between font-[pretendard-m] text-[6.667vw] ml-[7.778vw]'}>
                    <div className={'w-[28.889vw]'}>1~2레벨</div>
                    <div className={'w-[28.889vw]'}>3~4레벨</div>
                    <div className={'w-[28.889vw]'}>5~6레벨</div>
                    <div className={'w-[28.889vw]'}>7~8레벨</div>
                    <div className={'w-[28.889vw]'}>9~10레벨</div>
                    <div className={'w-[28.889vw]'}>11~12레벨</div>
                  </div>
                </div>
                <div className={'w-[276.667vw] h-[20vw] pl-[4.063vw] flex items-center bg-[#ffffff] rounded-[4.167vw] mt-[5.556vw]'}>
                  <div className={'w-[37.778vw] h-[11.111vw] flex justify-center items-center font-[pretendard-m] text-[6.667vw] text-[#000000] bg-[#ffffff] rounded-full'}>
                    정규교과
                  </div>
                  <div className={'w-[217.778vw] flex justify-between font-[pretendard-m] text-[6.667vw] ml-[7.778vw]'}>
                    <div className={'w-[28.889vw]'}>1학년</div>
                    <div className={'w-[28.889vw]'}>2학년</div>
                    <div className={'w-[28.889vw]'}>3학년</div>
                    <div className={'w-[28.889vw]'}>4학년</div>
                    <div className={'w-[28.889vw]'}>5학년</div>
                    <div className={'w-[28.889vw]'}>6학년</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
      <div className={'relative'} ref={curriculumContent3}>
        <picture className={'relative'}>
          <source media="(min-width: 640px)" srcSet={CurriculumBg1}/>
          <source media="(max-width: 639px)" srcSet={CurriculumMobileBg1}/>
          <img src={CurriculumMobileBg1} alt={'curriculum_mobile_bg_1'} className={'w-full'}/>
        </picture>
        <CSSTransition in={showCurriculumContent31} timeout={700} classNames={'curriculum-content-3-1-1'} mountOnEnter unmountOnExit>
          <div className={'absolute top-[119.444vw] sm:top-[26.927vw] left-[4.444vw] sm:left-[34.271vw]'}>
            <picture>
              <source media="(min-width: 640px)" srcSet={StepLine}/>
              <source media="(max-width: 639px)" srcSet={MobileStepLine}/>
              <img src={MobileStepLine} alt={'stop_line'} className={'w-[91.111vw] sm:w-[36.042vw]'}/>
            </picture>
          </div>
        </CSSTransition>
        <CSSTransition in={showCurriculumContent3} timeout={700} classNames={'curriculum-content-3-1'} mountOnEnter unmountOnExit>
          <img src={Step} alt={'step'} className={'w-[27.778vw] sm:w-[6.250vw] absolute top-[52.222vw] sm:top-[10.052vw] left-[15vw] sm:left-[18.75vw]'}/>
        </CSSTransition>
        <CSSTransition in={showCurriculumContent3} timeout={700} classNames={'curriculum-content-3-1'} mountOnEnter unmountOnExit>
          <div className={'absolute top-[56.389vw] sm:top-[11.875vw] left-[47.222vw] sm:left-[26.667vw] font-[nanum-square-round-eb] text-[8.333vw] sm:text-[2.604vw] leading-snug sm:leading-none'}>
            <div className={'hidden sm:block'}>
              일프로연산 커리큘럼
            </div>
            <div className={'block sm:hidden'}>
              일프로연산<br/>
              커리큘럼
            </div>
          </div>
        </CSSTransition>
        <img src={BalloonGogo} alt={'balloon_gogo'} className={'hidden sm:block w-[15.417vw] absolute top-[16.042vw] right-[4.844vw] animate-wiggle'}/>
        <img src={BalloonBero} alt={'balloon_bero'} className={'hidden sm:block w-[15.937vw] absolute top-[62.604vw] left-[4.323vw] animate-wiggle'}/>
        <div className={'absolute top-[97.778vw] sm:top-[22.917vw] left-[13.472vw] sm:left-[24.063vw]'} ref={curriculumContent31}>
          <CSSTransition in={showCurriculumContent31} timeout={700} classNames={'curriculum-content-3-1-1'} mountOnEnter unmountOnExit>
            <div>
              <div className={'flex justify-center'}>
                <img src={Curriculum21} alt={'curriculum_2_1'} className={'w-[72.222vw] sm:w-[20vw] rounded-[4.167vw] sm:rounded-[1.042vw] shadow-[4px_4px_10px_3px_rgba(0,0,0,0.2)]'}/>
              </div>
              <div className={'mt-[6.769vw] sm:mt-[1.25vw] flex justify-center'}>
                <div
                  className={'w-[72.222vw] sm:w-[20vw] h-[16.667vw] sm:h-[3.646vw] flex justify-center items-center bg-[#5C6DFF] rounded-full font-[nanum-square-round-eb] text-[#ffffff] text-[8.333vw] sm:text-[1.979vw]'}>
                  학습영상
                </div>
              </div>
              <div className={'w-[73.056vw] sm:w-auto flex justify-center font-[pretendard-m] text-[5vw] sm:text-[1.25vw] text-center mt-[6.561vw] sm:mt-[1.25vw]'}>
                아이들 눈높이에 맞춘 스토리텔링으로<br/>
                이해하기 어려운 수학 개념을<br/>
                캐릭터들과 재미있게 학습합니다.
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[216.842vw] sm:top-[31.302vw] left-[12.917vw] sm:left-[55.937vw]'} ref={curriculumContent32}>
          <CSSTransition in={showCurriculumContent32} timeout={700} classNames={'curriculum-content-3-2-1'} mountOnEnter unmountOnExit>
            <div>
              <div className={'flex justify-center'}>
                <img src={Curriculum22} alt={'curriculum_2_2'} className={'w-[72.222vw] sm:w-[20vw] rounded-[4.167vw] sm:rounded-[1.042vw] shadow-[4px_4px_10px_3px_rgba(0,0,0,0.2)]'}/>
              </div>
              <div className={'mt-[6.769vw] sm:mt-[1.25vw] flex justify-center'}>
                <div
                  className={'w-[72.222vw] sm:w-[20vw] h-[16.667vw] sm:h-[3.646vw] flex justify-center items-center bg-[#5C6DFF] rounded-full font-[nanum-square-round-eb] text-[#ffffff] text-[8.333vw] sm:text-[1.979vw]'}>
                  배우기
                </div>
              </div>
              <div className={'w-[74.167vw] sm:w-auto flex justify-center font-[pretendard-m] text-[5vw] sm:text-[1.25vw] text-center mt-[6.561vw] sm:mt-[1.25vw]'}>
                수학 교구처럼 화면의 물체를 보고<br/>
                터치하면서 학습의 기본이 되는 개념을<br/>
                쉽게 이해할 수 있습니다.
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[335.903vw] sm:top-[55.365vw] left-[10.139vw] sm:left-[29.375vw]'} ref={curriculumContent33}>
          <CSSTransition in={showCurriculumContent33} timeout={700} classNames={'curriculum-content-3-3-1'} mountOnEnter unmountOnExit>
            <div>
              <div className={'flex justify-center'}>
                <img src={Curriculum23} alt={'curriculum_2_3'} className={'w-[72.222vw] sm:w-[20vw] rounded-[4.167vw] sm:rounded-[1.042vw] shadow-[4px_4px_10px_3px_rgba(0,0,0,0.2)]'}/>
              </div>
              <div className={'mt-[6.769vw] sm:mt-[1.25vw] flex justify-center'}>
                <div
                  className={'w-[72.222vw] sm:w-[20vw] h-[16.667vw] sm:h-[3.646vw] flex justify-center items-center bg-[#5C6DFF] rounded-full font-[nanum-square-round-eb] text-[#ffffff] text-[8.333vw] sm:text-[1.979vw]'}>
                  익히기
                </div>
              </div>
              <div className={'w-[79.722vw] sm:w-auto flex justify-center font-[pretendard-m] text-[5vw] sm:text-[1.25vw] text-center mt-[6.561vw] sm:mt-[1.25vw]'}>
                다양한 연산 유형을 접하면서 배운 개념을<br/>
                잘 이해했는지 확인하고 빠르고 정확하게<br/>
                푸는 힘을 기릅니다.
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[454.964vw] sm:top-[79.271vw] left-[10.139vw] sm:left-[55.937vw]'} ref={curriculumContent34}>
          <CSSTransition in={showCurriculumContent34} timeout={700} classNames={'curriculum-content-3-4-1'} mountOnEnter unmountOnExit>
            <div>
              <div className={'flex justify-center'}>
                <img src={Curriculum24} alt={'curriculum_2_4'} className={'w-[72.222vw] sm:w-[20vw] rounded-[4.167vw] sm:rounded-[1.042vw] shadow-[4px_4px_10px_3px_rgba(0,0,0,0.2)]'}/>
              </div>
              <div className={'mt-[6.769vw] sm:mt-[1.25vw] flex justify-center'}>
                <div
                  className={'w-[72.222vw] sm:w-[20vw] h-[16.667vw] sm:h-[3.646vw] flex justify-center items-center bg-[#5C6DFF] rounded-full font-[nanum-square-round-eb] text-[#ffffff] text-[8.333vw] sm:text-[1.979vw]'}>
                  심화학습
                </div>
              </div>
              <div className={'w-[79.722vw] sm:w-full flex justify-center font-[pretendard-m] text-[5vw] sm:text-[1.25vw] text-center mt-[6.561vw] sm:mt-[1.25vw]'}>
                복잡한 수학 개념을 실생활에 접목하여<br/>
                쉽게 이해하고 생활 속에서 수학의 원리를<br/>
                이해할 수 있습니다.
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[574.028vw] sm:top-[92.396vw] left-[9.028vw] sm:left-[24.063vw]'} ref={curriculumContent35}>
          <CSSTransition in={showCurriculumContent35} timeout={700} classNames={'curriculum-content-3-5-1'} mountOnEnter unmountOnExit>
            <div>
              <div className={'flex justify-center'}>
                <img src={Curriculum25} alt={'curriculum_2_5'} className={'w-[72.222vw] sm:w-[20vw] rounded-[4.167vw] sm:rounded-[1.042vw] shadow-[4px_4px_10px_3px_rgba(0,0,0,0.2)]'}/>
              </div>
              <div className={'mt-[6.769vw] sm:mt-[1.25vw] flex justify-center'}>
                <div
                  className={'w-[72.222vw] sm:w-[20vw] h-[16.667vw] sm:h-[3.646vw] flex justify-center items-center bg-[#5C6DFF] rounded-full font-[nanum-square-round-eb] text-[#ffffff] text-[8.333vw] sm:text-[1.979vw]'}>
                  오답노트
                </div>
              </div>
              <div className={'w-[81.944vw] sm:w-auto flex justify-center font-[pretendard-m] text-[5vw] sm:text-[1.25vw] text-center mt-[6.561vw] sm:mt-[1.25vw]'}>
                학습한 스테이지 중에 어려워 하는 유형이<br/>
                있다면 완벽 마스터할 때까지 반복해서<br/>
                학습합니다.
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'w-full absolute top-[712.014vw] sm:top-[122.396vw]'} ref={curriculumContent36}>
          <CSSTransition in={showCurriculumContent36} timeout={700} classNames={'curriculum-content-3-6-1'} mountOnEnter unmountOnExit>
            <div className={'relative h-[83.819vw] sm:h-[21.152vw]'}>
              <img src={Trophy} alt={'trophy'} className={'w-[56.667vw] sm:w-[16.667vw] absolute top-0 left-[26.375vw] sm:left-[41.667vw] animate-scale'}/>
              <picture>
                <source media="(min-width: 640px)" srcSet={Shining}/>
                <source media="(max-width: 639px)" srcSet={mobileShining}/>
                <img src={Shining} alt={'shining'} className={'w-[54.992vw] sm:w-[28.021vw] absolute top-[-4.478vw] sm:top-0 left-[16.958vw] sm:left-[35.365vw]'}/>
              </picture>
              <div className={'w-full absolute bottom-0 flex justify-center'}>
                <div
                  className={'w-[72.222vw] sm:w-[20vw] h-[16.667vw] sm:h-[3.646vw] flex justify-center items-center bg-[#5C6DFF] rounded-full font-[nanum-square-round-eb] text-[8.333vw] sm:text-[1.979vw] text-[#ffffff]'}>
                  상위 1% 달성
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
      <div className={'relative mt-[35.556vw] sm:mt-[12.656vw] mb-[35.556vw] sm:mb-[12.5vw]'} ref={curriculumContent8}>
        <div className={'relative'}>
          <picture className={'w-full flex justify-center'}>
            <source media="(min-width: 640px)" srcSet={CurriculumBg2}/>
            <source media="(max-width: 639px)" srcSet={CurriculumMobileBg2}/>
            <img src={CurriculumMobileBg2} alt={'curriculum_bg_2'} className={'w-[91.111vw] sm:w-full'}/>
          </picture>
        </div>
        <div className={'hidden sm:block'}>
          <CSSTransition in={showCurriculumContent8} timeout={700} classNames={'curriculum-content-8-1'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[4.167vw] left-[22.917vw] font-[nanum-square-round-eb] text-[2.188vw] text-[#ffffff] leading-none'}>
              수많은 1등을 만든
            </div>
          </CSSTransition>
          <CSSTransition in={showCurriculumContent8} timeout={700} classNames={'curriculum-content-8-1'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[7.292vw] left-[22.917vw] font-[nanum-square-round-eb] text-[2.708vw] text-[#ffffff] leading-none'}>
              <span className={'text-[#FFFA7E]'}>기적의 커리큘럼</span>을
            </div>
          </CSSTransition>
          <CSSTransition in={showCurriculumContent8} timeout={700} classNames={'curriculum-content-8-1'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[10.729vw] left-[22.917vw] font-[nanum-square-round-eb] text-[2.708vw] text-[#ffffff] leading-none'}>
              경험해 보세요!
            </div>
          </CSSTransition>
        </div>
        <div className={'block sm:hidden'}>
          <CSSTransition in={showCurriculumContent8} timeout={700} classNames={'curriculum-content-8-1'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[22.222vw] font-[nanum-square-round-eb] text-[8.889vw] text-[#ffffff] text-center'}>
              수많은 1등을 만든<br/>
              <span className={'text-[#FFFA7E]'}>기적의 커리큘럼</span>을<br/>
              경험해보세요.
            </div>
          </CSSTransition>
        </div>
        <img src={Curriculum3} alt={'curriculum_3'} className={'w-[91.111vw] sm:w-[32.604vw] absolute bottom-0 left-[4.444vw] sm:left-[52.292vw]'}/>
        <div className={'absolute top-[75.556vw] sm:top-[21.458vw] left-[13.889vw] sm:left-[22.917vw]'}>
          <CSSTransition in={showCurriculumContent8} timeout={900} classNames={'curriculum-content-8-2'} mountOnEnter unmountOnExit>
            <a
              target={'_blank'}
              href={'http://1promath.co.kr/pages/store/onepromath.php'}
              className={'w-[72.222vw] sm:w-[13.542vw] h-[20vw] sm:h-[4.896vw] flex justify-center items-center font-[nanum-square-round-eb] text-[#ffffff] text-[6.667vw] sm:text-[1.563vw] border-[0.556vw] sm:border-[0.156vw] border-[#ffffff] rounded-[4.167vw] sm:rounded-[1.042vw] cursor-pointer'}>
              3일 무료체험
            </a>
          </CSSTransition>
        </div>
        <div className={'absolute top-[102.222vw] sm:top-[21.458vw] left-[13.889vw] sm:left-[38.75vw]'}>
          <CSSTransition in={showCurriculumContent8} timeout={900} classNames={'curriculum-content-8-2'} mountOnEnter unmountOnExit>
            <a
              target={'_blank'}
              href={'https://smartstore.naver.com/1promath'}
              className={'w-[72.222vw] sm:w-[13.542vw] h-[20vw] sm:h-[4.896vw] flex justify-center items-center font-[nanum-square-round-eb] text-[#ffffff] text-[6.667vw] sm:text-[1.563vw] border-[0.556vw] sm:border-[0.156vw] border-[#ffffff] rounded-[4.167vw] sm:rounded-[1.042vw] cursor-pointer'}>
              구매하러 가기
            </a>
          </CSSTransition>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Curriculum