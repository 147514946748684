import { CSSTransition } from 'react-transition-group';
import ScrollToTopButton from '../../component/ScrollToTopButton';
import aicoursewareMobileBg1 from '../../resource/image/aicourseware_mobile_bg_1.png';
import aicoursewareBg1 from '../../resource/image/aicourseware_bg_1.png';
import ddui from '../../resource/image/ddui.png';
import Footer from '../../component/Footer';
import { ReactComponent as Up } from '../../resource/image/up.svg';
import { ReactComponent as Down } from '../../resource/image/down.svg';
import aicourseware_1_1 from '../../resource/image/aicourseware_1_1.png';
import aicourseware_1_2 from '../../resource/image/aicourseware_1_2.png';
import aicourseware_1_3 from '../../resource/image/aicourseware_1_3.png';
import aicourseware_2_1 from '../../resource/image/aicourseware_2_1.png';
import aicourseware_2_2 from '../../resource/image/aicourseware_2_2.png';
import aicourseware_2_3 from '../../resource/image/aicourseware_2_3.png';
import aicourseware_3 from '../../resource/image/aicourseware_3.png';
import aicoursewareMobile_1_1 from '../../resource/image/aicourseware_mobile_1_1.png';
import aicoursewareMobile_1_2 from '../../resource/image/aicourseware_mobile_1_2.png';
import aicoursewareMobile_1_3 from '../../resource/image/aicourseware_mobile_1_3.png';
import aicoursewareMobile_2_1 from '../../resource/image/aicourseware_mobile_2_1.png';
import aicoursewareMobile_2_2 from '../../resource/image/aicourseware_mobile_2_2.png';
import aicoursewareMobile_2_3 from '../../resource/image/aicourseware_mobile_2_3.png';
import aicoursewareMobile_3 from '../../resource/image/aicourseware_mobile_3.png';
import { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import './AiCourseware.css';
import { ReactComponent as ChevronRight } from '../../resource/image/chevron-right.svg';

const AiCourseware = () => {
  const [showContent1, setShowContent1] = useState(false);
  const [showContent2, setShowContent2] = useState(false);
  const [showContent3, setShowContent3] = useState(false);
  const [showContent4, setShowContent4] = useState(false);
  const [showContent5, setShowContent5] = useState(false);
  const content1 = useRef(null);
  const content2 = useRef(null);
  const content3 = useRef(null);
  const content4 = useRef(null);
  const content5 = useRef(null);

  const controlScroll = () => {
    let halfInternalHeight = window.innerHeight / 1.5;
    // const homeContentTwoOneScroll = homeContentTwoOne.current.getBoundingClientRect()
    const content1Scroll = content1.current.getBoundingClientRect();
    setShowContent1(content1Scroll.top <= halfInternalHeight);
    const content2Scroll = content2.current.getBoundingClientRect();
    setShowContent2(content2Scroll.top <= halfInternalHeight);
    const content3Scroll = content3.current.getBoundingClientRect();
    setShowContent3(content3Scroll.top <= halfInternalHeight);
    const content4Scroll = content4.current.getBoundingClientRect();
    setShowContent4(content4Scroll.top <= halfInternalHeight);
    const content5Scroll = content5.current.getBoundingClientRect();
    setShowContent5(content5Scroll.top <= halfInternalHeight);
  };

  const handleScroll = throttle(controlScroll, 200);

  const handleResize = throttle(controlScroll, 200);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      // cleanup function
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => window.removeEventListener('resize', handleResize);
  }, []); // 빈 의존성 배열을 사용하여 마운트 시에만 이벤트 리스너를 추가

  const [menuOnOff, setMenuOnOff] = useState({
    classManagement: true,
    weeklyLearning: false,
    averageLearning: false,
    attendance: true,
    learningAnalytics: false,
    excellentAndWeak: false,
  });

  const handleMenuOnOff = (menu) => {
    if (menu === 'classManagement') {
      setMenuOnOff({
        ...menuOnOff, // 기존 값들 유지
        classManagement: !menuOnOff.classManagement,
        weeklyLearning: false,
        averageLearning: false,
      });
    } else if (menu === 'weeklyLearning') {
      setMenuOnOff({
        ...menuOnOff, // 기존 값들 유지
        classManagement: false,
        weeklyLearning: !menuOnOff.weeklyLearning,
        averageLearning: false,
      });
    } else if (menu === 'averageLearning') {
      setMenuOnOff({
        ...menuOnOff, // 기존 값들 유지
        classManagement: false,
        weeklyLearning: false,
        averageLearning: !menuOnOff.averageLearning,
      });
    } else if (menu === 'attendance') {
      setMenuOnOff({
        ...menuOnOff,
        attendance: !menuOnOff.attendance,
        learningAnalytics: false,
        excellentAndWeak: false,
      });
    } else if (menu === 'learningAnalytics') {
      setMenuOnOff({
        ...menuOnOff,
        attendance: false,
        learningAnalytics: !menuOnOff.learningAnalytics,
        excellentAndWeak: false,
      });
    } else if (menu === 'excellentAndWeak') {
      setMenuOnOff({
        ...menuOnOff,
        attendance: false,
        learningAnalytics: false,
        excellentAndWeak: !menuOnOff.excellentAndWeak,
      });
    }
  };

  return (
    <div className={'w-[100vw] overflow-hidden'}>
      <div className={'w-full h-[953.333vw] sm:h-[245.99vw] relative'}>
        <div className={'block sm:hidden'}>
          <ScrollToTopButton/>
        </div>
        <div className={'relative'}>
          <picture className={'relative'}>
            <source media="(min-width: 640px)" srcSet={aicoursewareBg1}/>
            <source media="(max-width: 639px)" srcSet={aicoursewareMobileBg1}/>
            <img src={aicoursewareBg1} alt={'aicourseware_bg_1'} className={'w-full'}/>
          </picture>
          <div
            className={'absolute top-[28.333vw] sm:top-[14.635vw] left-[14.444vw] sm:left-[17.24vw] text-[7.778vw] sm:text-[3.125vw] font-[nanum-barun-gothic-b] text-[#ffffff] leading-normal sm:leading-tight text-center sm:text-left'}>
            일프로연산 AI코스웨어<br/>
            LMS 알아보기
          </div>
          <div className={'hidden sm:block absolute top-[25.26vw] left-[17.24vw] text-[1.406vw] font-[nanum-barun-gothic-r] text-[#DDEAFF] leading-loose'}>
            초등 전과정 정규 교과과정 연산 파트 100% 반영<br/>
            10명 중 9명이 경험한 연산 실력 향상<br/>
            교사용 관리 페이지 제공
          </div>
          <div className={'block sm:hidden absolute top-[55.833vw] left-[26.111vw] text-[#DDEAFF] text-[5vw] font-[nanum-barun-gothic-r]'}>
            교사용 관리 페이지 제공
          </div>
          <a
            target={'_blank'}
            href={'https://blog.naver.com/1promath_office/223217038856'}
            className={'w-[62.222vw] sm:w-[13.49vw] h-[15.556vw] sm:h-[4.167vw] absolute top-[147.222vw] sm:top-[36.198vw] left-[18.889vw] sm:left-[17.24vw] flex justify-center items-center bg-[#ffffff] rounded-[4.167vw] sm:rounded-[0.781vw] text-[#3E3E40] opacity-100 sm:opacity-90 cursor-pointer leading-none'}>
            <div className={'text-[5.556vw] sm:text-[1.25vw] font-[nanum-square-round-eb] leading-none'}>더 자세히 보기</div>
            &nbsp;&nbsp;&nbsp;
            <ChevronRight className={'w-[5.278vw] sm:w-[1.042vw] h-[5.278vw] sm:h-[1.042vw]'}/>
          </a>
        </div>
        <div
          className={'w-[91.111vw] sm:w-[62.5vw] h-[102.5vw] sm:h-[17.188vw] absolute top-[189.722vw] sm:top-[56.198vw] left-[4.444vw] sm:left-[18.75vw] bg-[#EFF4FF] rounded-[6.944vw] sm:rounded-[1.563vw]'}>
          <div
            className={'absolute top-[12.778vw] sm:top-[3.125vw] left-auto sm:left-[5.521vw] font-[nanum-square-round-eb] text-[5.556vw] sm:text-[1.563vw] leading-none w-full sm:w-auto text-center'}>학교
            도입 문의하기
          </div>
          <a
            href={'tel:010-9770-0231'}
            className={'absolute top-[22.778vw] sm:top-[5.677vw] left-auto sm:left-[5.521vw] font-[pretendard-b] text-[9.444vw] sm:text-[3.646vw] leading-none w-full sm:w-auto text-center'}>010-9770-0231</a>
          <div className={'hidden sm:block absolute top-[10.521vw] left-[5.521vw] font-[pretendard-m] text-[1.25vw] leading-normal'}>
            일프로연산을 학교에 도입하고 싶거나 도입 전 체험을 해보고 싶으시다면,<br/>
            언제든 편안하게 위 연락처로 문의해 주세요.
          </div>
          <div className={'block sm:hidden absolute top-[38.889vw] w-full font-[pretendard-m] text-[4.167vw] text-center'}>
            일프로연산을 학교에 도입하고 싶거나<br/>
            도입 전 체험을 해보고 싶으시다면,<br/>
            언제든 편안하게 위 연락처로 문의해 주세요.
          </div>
          <img src={ddui} alt={'ddui'} className={'w-[45vw] sm:w-[12.76vw] ht-[45.972vw] sm:h-[12.5vw] absolute top-[56.389vw] sm:top-[4.688vw] right-[23.056vw] sm:right-[4.896vw]'}/>
        </div>
        <div className={'absolute top-[308.611vw] sm:top-[80.938vw] w-full text-center font-[nanum-square-round-eb] text-[6.667vw] sm:text-[2.188vw] leading-tight'} ref={content1}>
          <CSSTransition in={showContent1} timeout={300} classNames={'aicourseware-content-1-1'} mountOnEnter unmountOnExit>
            <div>
              바쁜 선생님을 위한<br/>
              쉽고 편리한 학습 관리
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[396.389vw] sm:top-[88.594vw] left-[5.556vw] sm:left-[11.771vw]'} ref={content2}>
          <CSSTransition in={showContent2} timeout={300} classNames={'aicourseware-content-1-2'} mountOnEnter unmountOnExit>
            <div
              onClick={() => handleMenuOnOff('classManagement')}
              className={menuOnOff.classManagement ? `w-[88.889vw] sm:w-[22.552vw] h-[60.278vw] sm:h-[13.958vw] border-[#EFF4FF] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] bg-[#EFF4FF] cursor-pointer` : `w-[88.889vw] sm:w-[22.552vw] h-[20vw] sm:h-[4.688vw] border-[#D9D9D9] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] cursor-pointer`}>
              <div className={'flex justify-between items-center px-[7.222vw] sm:px-[1.719vw] h-[20vw] sm:h-[4.688vw]'}>
                <div className={'font-[pretendard-sb] text-[6.111vw] sm:text-[1.458vw] leading-none'}>학급 관리</div>
                <div>
                  {menuOnOff.classManagement ? <Up className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/> : <Down className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/>}
                </div>
              </div>
              {menuOnOff.classManagement ? (
                <div className={'px-[7.222vw] sm:px-[1.719vw] font-[pretendard-r] text-[5vw] sm:text-[1.25vw] text-[#4D4D4D]'}>
                  한 눈에 보기 편하게 구성되어 학급이 많아도 간편하게 모든 학급 관리가 가능해요.
                </div>
              ) : null}
            </div>
          </CSSTransition>
          <CSSTransition in={showContent2} timeout={500} classNames={'aicourseware-content-1-3'} mountOnEnter unmountOnExit>
            <div
              onClick={() => handleMenuOnOff('weeklyLearning')}
              className={menuOnOff.weeklyLearning ? `w-[88.889vw] sm:w-[22.552vw] h-[60.278vw] sm:h-[13.958vw] border-[#EFF4FF] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] bg-[#EFF4FF] mt-[9.444vw] sm:mt-[1.667vw] cursor-pointer` : `w-[88.889vw] sm:w-[22.552vw] h-[20vw] sm:h-[4.688vw] border-[#D9D9D9] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] mt-[9.444vw] sm:mt-[1.667vw] cursor-pointer`}>
              <div className={'flex justify-between items-center px-[7.222vw] sm:px-[1.719vw] h-[20vw] sm:h-[4.688vw]'}>
                <div className={'font-[pretendard-sb] text-[6.111vw] sm:text-[1.458vw] leading-none'}>주간 학습 현황</div>
                <div>
                  {menuOnOff.weeklyLearning ? <Up className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/> : <Down className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/>}
                </div>
              </div>
              {menuOnOff.weeklyLearning ? (
                <div className={'px-[7.222vw] sm:px-[1.719vw] font-[pretendard-r] text-[5vw] sm:text-[1.25vw] text-[#4D4D4D]'}>
                  주별로 학생들의 학습시간, 정확도, 학습량을 학생 이름, 학습량별로 내용을 정렬해 빠르게 파악할 수 있어요.
                </div>
              ) : null}
            </div>
          </CSSTransition>
          <CSSTransition in={showContent2} timeout={700} classNames={'aicourseware-content-1-4'} mountOnEnter unmountOnExit>
            <div
              onClick={() => handleMenuOnOff('averageLearning')}
              className={menuOnOff.averageLearning ? `w-[88.889vw] sm:w-[22.552vw] h-[60.278vw] sm:h-[13.958vw] border-[#EFF4FF] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] bg-[#EFF4FF] mt-[9.444vw] sm:mt-[1.667vw] cursor-pointer` : `w-[88.889vw] sm:w-[22.552vw] h-[20vw] sm:h-[4.688vw] border-[#D9D9D9] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] mt-[9.444vw] sm:mt-[1.667vw] cursor-pointer`}>
              <div className={'flex justify-between items-center px-[7.222vw] sm:px-[1.719vw] h-[20vw] sm:h-[4.688vw]'}>
                <div className={'font-[pretendard-sb] text-[6.111vw] sm:text-[1.458vw] leading-none'}>평균 학습 현황</div>
                <div>
                  {menuOnOff.averageLearning ? <Up className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/> : <Down className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/>}
                </div>
              </div>
              {menuOnOff.averageLearning ? (
                <div className={'px-[7.222vw] sm:px-[1.719vw] font-[pretendard-r] text-[5vw] sm:text-[1.25vw] text-[#4D4D4D]'}>
                  우리반 아이들의 반 평균 학습량, 정확도, 학습 시간을 쉽게 확인할 수 있어요.
                </div>
              ) : null}
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[333.611vw] sm:top-[88.594vw] left-[5.556vw] sm:left-[36.198vw]'}>
          <CSSTransition in={showContent2} timeout={300} classNames={'aicourseware-content-1-2'} mountOnEnter unmountOnExit>
            <div>
              <CSSTransition in={menuOnOff.classManagement} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
                <div className={'absolute top-0 left-0 w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'}>
                  <picture>
                    <source media="(min-width: 640px)" srcSet={aicourseware_1_1} className={'w-full'}/>
                    <source media="(max-width: 639px)" srcSet={aicoursewareMobile_1_1} className={'w-full'}/>
                    <img className={'w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'} alt={'aicourseware_1_1'}/>
                  </picture>
                </div>
              </CSSTransition>
              <CSSTransition in={menuOnOff.weeklyLearning} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
                <div className={'absolute top-0 left-0 w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'}>
                  <picture>
                    <source media="(min-width: 640px)" srcSet={aicourseware_1_2} className={'w-full'}/>
                    <source media="(max-width: 639px)" srcSet={aicoursewareMobile_1_2} className={'w-full'}/>
                    <img className={'w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'} alt={'aicourseware_1_2'}/>
                  </picture>
                </div>
              </CSSTransition>
              <CSSTransition in={menuOnOff.averageLearning} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
                <div className={'absolute top-0 left-0 w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'}>
                  <picture>
                    <source media="(min-width: 640px)" srcSet={aicourseware_1_3} className={'w-full'}/>
                    <source media="(max-width: 639px)" srcSet={aicoursewareMobile_1_3} className={'w-full'}/>
                    <img className={'w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'} alt={'aicourseware_1_3'}/>
                  </picture>
                </div>
              </CSSTransition>
              <CSSTransition in={!menuOnOff.classManagement && !menuOnOff.weeklyLearning && !menuOnOff.averageLearning} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
                <div className={'absolute top-0 left-0 w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'}>
                  <picture>
                    <source media="(min-width: 640px)" srcSet={aicourseware_1_1} className={'w-full'}/>
                    <source media="(max-width: 639px)" srcSet={aicoursewareMobile_1_1} className={'w-full'}/>
                    <img className={'w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'} alt={'aicourseware_1_1'}/>
                  </picture>
                </div>
              </CSSTransition>
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[537.778vw] sm:top-[130.885vw] w-full text-center font-[nanum-square-round-eb] text-[6.667vw] sm:text-[2.188vw] leading-tight'} ref={content3}>
          <CSSTransition in={showContent3} timeout={300} classNames={'aicourseware-content-1-1'} mountOnEnter unmountOnExit>
            <div>
              우리반 아이들의<br/>
              학습 데이터를 좀 더 꼼꼼하게
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[625.556vw] sm:top-[138.542vw] left-[5.556vw] sm:left-[11.771vw]'} ref={content4}>
          <CSSTransition in={showContent4} timeout={300} classNames={'aicourseware-content-1-2'} mountOnEnter unmountOnExit>
            <div
              onClick={() => handleMenuOnOff('attendance')}
              className={menuOnOff.attendance ? `w-[88.889vw] sm:w-[22.552vw] h-[60.278vw] sm:h-[13.958vw] border-[#EFF4FF] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] bg-[#EFF4FF] cursor-pointer` : `w-[88.889vw] sm:w-[22.552vw] h-[20vw] sm:h-[4.688vw] border-[#D9D9D9] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] cursor-pointer`}>
              <div className={'flex justify-between items-center px-[7.222vw] sm:px-[1.719vw] h-[20vw] sm:h-[4.688vw]'}>
                <div className={'font-[pretendard-sb] text-[6.111vw] sm:text-[1.458vw] leading-none'}>출석 현황</div>
                <div>
                  {menuOnOff.attendance ? <Up className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/> : <Down className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/>}
                </div>
              </div>
              {menuOnOff.attendance ? (
                <div className={'px-[7.222vw] sm:px-[1.719vw] font-[pretendard-r] text-[5vw] sm:text-[1.25vw] text-[#4D4D4D]'}>
                  이번 달 전체 출석을 한눈에 보기 쉽게 파악할 수 있어요. 출석일 수 외에도 평균 학습 스테이지 수도 확인할 수 있어요.
                </div>
              ) : null}
            </div>
          </CSSTransition>
          <CSSTransition in={showContent4} timeout={500} classNames={'aicourseware-content-1-3'} mountOnEnter unmountOnExit>
            <div
              onClick={() => handleMenuOnOff('learningAnalytics')}
              className={menuOnOff.learningAnalytics ? `w-[88.889vw] sm:w-[22.552vw] h-[60.278vw] sm:h-[13.958vw] border-[#EFF4FF] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] bg-[#EFF4FF] mt-[9.444vw] sm:mt-[1.667vw] cursor-pointer` : `w-[88.889vw] sm:w-[22.552vw] h-[20vw] sm:h-[4.688vw] border-[#D9D9D9] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] mt-[9.444vw] sm:mt-[1.667vw] cursor-pointer`}>
              <div className={'flex justify-between items-center px-[7.222vw] sm:px-[1.719vw] h-[20vw] sm:h-[4.688vw]'}>
                <div className={'font-[pretendard-sb] text-[6.111vw] sm:text-[1.458vw] leading-none'}>학습 분석</div>
                <div>
                  {menuOnOff.learningAnalytics ? <Up className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/> : <Down className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/>}
                </div>
              </div>
              {menuOnOff.learningAnalytics ? (
                <div className={'px-[7.222vw] sm:px-[1.719vw] font-[pretendard-r] text-[5vw] sm:text-[1.25vw] text-[#4D4D4D]'}>
                  이번달 평균 학습량, 정확도, 학습 시간을 지난 4개월 데이터와 비교할 수 있어요.
                </div>
              ) : null}
            </div>
          </CSSTransition>
          <CSSTransition in={showContent4} timeout={700} classNames={'aicourseware-content-1-4'} mountOnEnter unmountOnExit>
            <div
              onClick={() => handleMenuOnOff('excellentAndWeak')}
              className={menuOnOff.excellentAndWeak ? `w-[88.889vw] sm:w-[22.552vw] h-[60.278vw] sm:h-[13.958vw] border-[#EFF4FF] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] bg-[#EFF4FF] mt-[9.444vw] sm:mt-[1.667vw] cursor-pointer` : `w-[88.889vw] sm:w-[22.552vw] h-[20vw] sm:h-[4.688vw] border-[#D9D9D9] border-[0.104vw] rounded-[2.778vw] sm:rounded-[0.521vw] mt-[9.444vw] sm:mt-[1.667vw] cursor-pointer`}>
              <div className={'flex justify-between items-center px-[7.222vw] sm:px-[1.719vw] h-[20vw] sm:h-[4.688vw]'}>
                <div className={'font-[pretendard-sb] text-[6.111vw] sm:text-[1.458vw] leading-none'}>우수 및 취약점 파악</div>
                <div>
                  {menuOnOff.excellentAndWeak ? <Up className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/> : <Down className={'w-[5vw] sm:w-[0.97vw] h-[3.106vw] sm:h-[0.97vw]'}/>}
                </div>
              </div>
              {menuOnOff.excellentAndWeak ? (
                <div className={'px-[7.222vw] sm:px-[1.719vw] font-[pretendard-r] text-[5vw] sm:text-[1.25vw] text-[#4D4D4D]'}>
                  학생 개개인의 우수 및 취약 스테이지를 파악해 어떤 부분이 보충학습이 필요한지 파악하기 쉬워요.
                </div>
              ) : null}
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[562.778vw] sm:top-[138.542vw] left-[5.556vw] sm:left-[36.198vw]'}>
          <CSSTransition in={showContent4} timeout={300} classNames={'aicourseware-content-1-1'} mountOnEnter unmountOnExit>
            <div>
              <CSSTransition in={menuOnOff.attendance} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
                <div className={'absolute top-0 left-0 w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'}>
                  <picture>
                    <source media="(min-width: 640px)" srcSet={aicourseware_2_1} className={'w-full'}/>
                    <source media="(max-width: 639px)" srcSet={aicoursewareMobile_2_1} className={'w-full'}/>
                    <img className={'w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'} alt={'aicourseware_2_1'}/>
                  </picture>
                </div>
              </CSSTransition>
              <CSSTransition in={menuOnOff.learningAnalytics} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
                <div className={'absolute top-0 left-0 w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'}>
                  <picture>
                    <source media="(min-width: 640px)" srcSet={aicourseware_2_2} className={'w-full'}/>
                    <source media="(max-width: 639px)" srcSet={aicoursewareMobile_2_2} className={'w-full'}/>
                    <img className={'w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'} alt={'aicourseware_2_2'}/>
                  </picture>
                </div>
              </CSSTransition>
              <CSSTransition in={menuOnOff.excellentAndWeak} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
                <div className={'absolute top-0 left-0 w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'}>
                  <picture>
                    <source media="(min-width: 640px)" srcSet={aicourseware_2_3} className={'w-full'}/>
                    <source media="(max-width: 639px)" srcSet={aicoursewareMobile_2_3} className={'w-full'}/>
                    <img className={'w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'} alt={'aicourseware_2_3'}/>
                  </picture>
                </div>
              </CSSTransition>
              <CSSTransition in={!menuOnOff.attendance && !menuOnOff.learningAnalytics && !menuOnOff.excellentAndWeak} timeout={300} classNames="fade" mountOnEnter unmountOnExit>
                <div className={'absolute top-0 left-0 w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'}>
                  <picture>
                    <source media="(min-width: 640px)" srcSet={aicourseware_2_1} className={'w-full'}/>
                    <source media="(max-width: 639px)" srcSet={aicoursewareMobile_2_1} className={'w-full'}/>
                    <img className={'w-[88.889vw] sm:w-[52.083vw] h-[53.333vw] sm:h-[31.25vw]'} alt={'aicourseware_2_1'}/>
                  </picture>
                </div>
              </CSSTransition>
            </div>
          </CSSTransition>
        </div>
        <div className={'absolute top-[766.944vw] sm:top-[179.635vw] w-full text-center font-[nanum-square-round-eb] text-[6.667vw] sm:text-[2.188vw] leading-none'} ref={content5}>
          <CSSTransition in={showContent5} timeout={300} classNames={'aicourseware-content-1-2'} mountOnEnter unmountOnExit>
            <div>
              <div className={'hidden sm:block'}>
                이미 많은 학교에서 선택한 일프로연산
              </div>
              <div className={'block sm:hidden leading-tight'}>
                이미 많은 학교에서 선택한<br/>
                일프로연산
              </div>
            </div>
          </CSSTransition>
        </div>
        <CSSTransition in={showContent5} timeout={500} classNames={'aicourseware-content-1-3'} mountOnEnter unmountOnExit>
          <div className={'absolute top-[791.944vw] sm:top-[184.323vw] w-full text-center font-[pretendard-sb] text-[5vw] sm:text-[1.458vw] text-[#3E3E40] leading-tight'}>
            <div className={'hidden sm:block'}>
              전국 100만 학생, 전국 80곳 이상의 초등학교,<br/>
              2500곳 이상의 학원에서 선택한 일프로연산을 지금 바로 만나보세요.
            </div>
            <div className={'block sm:hidden'}>
              전국 100만 학생,<br/>
              전국 80곳 이상의 초등학교,<br/>
              2500곳 이상의 학원에서 선택한<br/>
              일프로연산을 지금 바로 만나보세요.
            </div>
          </div>
        </CSSTransition>
        <CSSTransition in={showContent5} timeout={700} classNames={'aicourseware-content-1-4'} mountOnEnter unmountOnExit>
          <div className={'absolute top-[829.167vw] sm:top-[191.667vw] left-[5.556vw] sm:left-[31.771vw]'}>
            <picture>
              <source media="(min-width: 640px)" srcSet={aicourseware_3} className={'w-full'}/>
              <source media="(max-width: 639px)" srcSet={aicoursewareMobile_3} className={'w-full'}/>
              <img className={'w-[88.889vw] sm:w-[36.458vw] h-[101.944vw] sm:h-[41.823vw]'} alt={'aicourseware_3'}/>
            </picture>
          </div>
        </CSSTransition>
      </div>
      <Footer/>
    </div>
  );
};

export default AiCourseware;
