import { CSSTransition } from 'react-transition-group';
import { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Faq.css';
import HeadsetDdui from '../../resource/image/headset_ddui.png';
import SoundOnOffButtonIphone from '../../resource/image/sound_on_off_button_iphone.png';
import ControlCenterIpad from '../../resource/image/control_center_ipad.png';
import ControlCenterIpad2 from '../../resource/image/control_center_ipad_2.png';
import StartGuide1 from '../../resource/image/start_guide_1.png';
import StartGuide2 from '../../resource/image/start_guide_2.png';
import StartGuide3 from '../../resource/image/start_guide_3.png';
import StartGuide4 from '../../resource/image/start_guide_4.png';
import StudyGuide1 from '../../resource/image/study_guide_1.png';
import StudyGuide2 from '../../resource/image/study_guide_2.png';
import StudyGuide3 from '../../resource/image/study_guide_3.png';
import StudyGuide4 from '../../resource/image/study_guide_4.png';
import { ReactComponent as Up } from '../../resource/image/up.svg';
import { ReactComponent as Down } from '../../resource/image/down.svg';
import { ReactComponent as Prev } from '../../resource/image/prev2.svg';
import { ReactComponent as Next } from '../../resource/image/next2.svg';
import { ReactComponent as Headset } from '../../resource/image/headset.svg';
import { ReactComponent as NaverTalk } from '../../resource/image/naver_talk.svg';
import Footer from '../../component/Footer';
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from '../../component/ScrollToTopButton';

const Faq = () => {
  const initialData = [
    {
      tab: '결제/환불/이용권',
      faq: [
        {
          id: 1,
          type: '이용권',
          title: '무료체험을 해볼 수 있을까요?',
          content: '일프로연산 애플리케이션을 다운로드하시면 별도의 신청 없이 3일 무료체험이 가능합니다.',
          hidden: true,
        },
        {
          id: 2,
          type: '이용권',
          title: '이용권이 언제 도착하나요?',
          content: '일프로연산 이용권 코드는 자정을 기준으로 구매하신 다음날, 고객님의 카카오톡 알림톡 (수취인 연락처)으로 발송됩니다.\n' +
            '단, 금요일~일요일, 공휴일 주문 건의 경우 그다음 주 월요일에 발송됨을 안내드립니다.\n\n' +
            '만일 카카오톡 알림톡 메시지를 통해 이용권을 받지 못하셨다면 주문 시간, 수취인 연락처를 확인 부탁드립니다. 확인이 어려우신 경우 스마트스토어 톡톡으로 문의하시면 신속히 도와드리겠습니다',
          hidden: true,
        },
        {
          id: 3,
          type: '환불',
          title: '환불하고 싶어요.',
          content: '온라인 교육 콘텐츠 관련 소비자보호법에 따라 구입 후 미사용 14일 이내는 전액 환불이 가능합니다.\n' +
            '사용 등록 또는 일주일 이후부터는 정상가 월 35,000원을 적용하여 환불 진행 가능합니다.\n' +
            '환불을 원하시는 경우 스마트스토어 톡톡으로 연락 주시면 신속히 도와드리겠습니다.\n' +
            '다만 공동구매를 통해 구입하신 경우, 할인율 및 수수료 등으로 인해 환불이 어려운 점 양해 부탁드립니다.',
          hidden: true,
        },
        {
          id: 4,
          type: '이용권',
          title: '이용권은 어떻게 등록하나요?',
          content: '사용 기기가 안드로이드(갤럭시), IOS(애플) 인지에 따라 등록 방법에 차이가 있습니다.\n\n' +
            '☆ 안드로이드 고객님!\n' +
            '일프로연산 앱 내 메인화면의 [학습 설정] - [구매/이용권] - [이용권 등록]에서 코드 15자리를 입력해 주세요.\n\n' +
            '☆ 애플 기기 고객님!\n' +
            '일프로연산 앱에서 회원가입을 먼저 진행해 주시고 이미 회원가입한 고객님이시라면\n' +
            '[link]http://voucher.1promath.co.kr[/link]\n' +
            '▲위 링크로 접속하셔서 로그인 후 코드 15자리를 입력 및 등록해 주세요. 이후 일프로연산 앱에서 접속 및 로그인을 하시면 됩니다.',
          hidden: true,
        },
        {
          id: 5,
          type: '이용권',
          title: '다자녀는 어떻게 이용하나요?',
          content: '일프로연산은 1개의 이용권을 구매하신다면 1개의 아이디로 최대 5개의 프로필을 만들어 사용 가능합니다. 이용권을 1개 구입하신 후에 아래 공유해 드린 다자녀 사용방법 링크를 참고하셔서 프로필을 추가하신다면 자녀분들이 각각 개인 프로필로 학습할 수 있습니다.\n' +
            '프로필마다 학습 레벨, 진도, 보석 개수 등 개별적으로 관리할 수 있으며, 다중 기기 등록, 다중 접속, 동시 사용이 가능합니다. 따라서 자녀분들이 동시간대에 각기 다른 모바일 기기로 이용이 가능합니다!\n\n' +
            '다자녀 사용방법 보러가기 ▼︎\n' +
            '[link]https:/www.notion.so/219f075cd9904b64b2c4702aedf699b3?pvs=4[/link]',
          hidden: true,
        },
        {
          id: 6,
          type: '이용권',
          title: '이용권은 언제까지 등록해야 하나요?',
          content: '일프로연산 이용권 코드는 분실 및 보안 등의 이유로 1년 이내 등록하시길 권장합니다.',
          hidden: true,
        },
        {
          id: 7,
          type: '이용권',
          title: '이용권 시작은 언제부터 인가요?',
          content: '고객님께서 이용권 코드번호를 등록하신 날로부터 시작됩니다.(구매하신 날짜 X) 기존에 기간이 남아있더라도 이용권 기간이 자동으로 추가되기 때문에 크게 걱정하지 않으셔도 됩니다.\n' +
            '{red}분실의 우려가 있기에 되도록이면 분실의 우려가 있기에 되도록이면 1년 이내로 등록해 주시는 것을 권장해 드립니다.{/red}\n\n' +
            '예를 들어 현재 이용 기간이 30일 남아있고 1년 이용권을 추가로 구매하여 등록하셨다면, 이용 기간이 30+365=395일로 자동 반영됩니다😊',
          hidden: true,
        },
        {
          id: 8,
          type: '이용권',
          title: '이용권 일시정지 기능이 있나요? 기간을 멈추고 싶어요.',
          content: '일프로연산의 경우, 아쉽게도 일시정지 기능이 따로 존재하지 않습니다.',
          hidden: true,
        },
        {
          id: 9,
          type: '결제',
          title: '자동 결제를 해지하고 싶어요.',
          content: '일프로연산의 경우, 자동으로 구독 갱신이 이루어지지 않습니다.\n' +
            '따라서 자동 결제가 되지 않기에 걱정하지 않으셔도 됩니다',
          hidden: true,
        },
        {
          id: 10,
          type: '이용권',
          title: '결제 내역을 확인하고 싶어요.',
          content: '일프로연산 애플리케이션 또는 일프로연산 학부모 앱에서 확인이 가능합니다.\n' +
            '1. 일프로연산> 학습 설정 > 구매/이용권(좌측 네 번째 탭) > 구매내역\n' +
            '2. 일프로연산 학부모 앱> 설정 > 결제 내역',
          hidden: true,
        },
        {
          id: 11,
          type: '이용권',
          title: '해외에 거주하고 있는데 이용권을 어떻게 받아볼 수 있나요?',
          content: '스마트스토어에서 이용권을 구매하시고 스마트스토어 톡톡으로 이메일 주소를 남겨주시면 해당 이메일로 이용권 코드를 발송해 드립니다. 해외에 거주하실 경우, 카카오톡 알림톡과 문자로 이용권을 받아보시기 어렵기에 이메일 주소를 추가로 요청드리오니 양해 부탁드립니다.',
          hidden: true,
        }
      ]
    },
    {
      tab: '계정',
      faq: [
        {
          id: 1,
          type: '아이디',
          title: '아이디를 잊어버렸어요.',
          content: '일프로연산 애플리케이션에서 확인 가능합니다.\n' +
            '학습 설정 > 계정 설정(좌측 두 번째 탭) > 아이디 확인',
          hidden: true,
        },
        {
          id: 2,
          type: '비밀번호',
          title: '비밀번호를 잊어버렸어요.',
          content: '비밀번호를 잊어버리신 경우, 개인정보 보안으로 인하여 비밀번호 초기화를 도와드리고 있습니다. 네이버 톡톡으로 일프로연산 아이디를 알려주시면 신속히 도와드리겠습니다',
          hidden: true,
        },
      ]
    },
    {
      tab: '시작 가이드',
      faq: [
        {
          id: 1,
          type: '',
          title: '일프로연산을 처음해요',
          content: '',
          image: 'StartGuide1',
          link: 'https://1promath.notion.site/57dde4e95e114698864ad3d065be3ebd?pvs=4',
          hidden: false,
        },
        {
          id: 2,
          type: '',
          title: '이렇게 활용해요',
          content: '',
          image: 'StartGuide2',
          link: 'https://1promath.notion.site/c7b2c25a269043db83b5111eab61a1c0?pvs=4',
          hidden: false,
        },
        {
          id: 3,
          type: '',
          title: '학부모 앱',
          content: '',
          image: 'StartGuide3',
          link: 'https://1promath.notion.site/adc541888f7b41b19b49eb8c8b8b663c?pvs=4',
          hidden: false,
        },
        {
          id: 4,
          type: '',
          title: 'PC 버전',
          content: '',
          image: 'StartGuide4',
          link: 'https://1promath.notion.site/PC-a7928564f5ea48398e4b9f9360d32f6c?pvs=4',
          hidden: false,
        }
      ]
    },
    {
      tab: '학습 가이드',
      faq: [
        {
          id: 1,
          type: '',
          title: '오늘의 학습',
          content: '',
          image: 'StudyGuide1',
          link: 'https://1promath.notion.site/d6d94b7ea7ce4d959f1a763574a01492?pvs=4',
          hidden: false,
        },
        {
          id: 2,
          type: '',
          title: '일프로도전',
          content: '',
          image: 'StudyGuide2',
          link: 'https://1promath.notion.site/07d569d28c2d4a1bb393ab0b85940e8c?pvs=4',
          hidden: false,
        },
        {
          id: 3,
          type: '',
          title: '학습결과',
          content: '',
          image: 'StudyGuide3',
          link: 'https://1promath.notion.site/d31e933b7b8449fb8c4f59b3c8d781d9?pvs=4',
          hidden: false,
        },
        {
          id: 4,
          type: '',
          title: '개념 (개념노트, 학습영상)',
          content: '',
          image: 'StudyGuide4',
          link: 'https://1promath.notion.site/1537396df9534c0da57755dea02301ae?pvs=4',
          hidden: false,
        }
      ]
    },
    {
      tab: '기타',
      faq: [
        {
          id: 1,
          type: '기타',
          title: '사운드가 들리지 않아요.',
          content: 'IOS(애플) 기기 사용자일 경우, 이미 기기에 설정되어 있는 세팅 값으로 인해 소리가 들리지 않을 수 있습니다.\n' +
            '- 아이폰 (모바일) : 기기 사이드에 무음 버튼이 on으로 되어있지 않는지 확인해 주세요\n\n' +
            '[img]SoundOnOffButtonIphone[/img]\n' +
            '- 아이패드 (태블릿)\n' +
            '기기의 [설정]-[제어센터]-[무음모드] 를 추가해 주시고 오른쪽 상단을 쓸어내리시면 빨간 종이 보이실 것입니다. 빨간 종을 눌러주시면 무음모드가 해제됩니다!\n\n' +
            '[img]ControlCenterIpad[/img]\n' +
            '[img]ControlCenterIpad2[/img]\n',
          hidden: true,
        },
        {
          id: 2,
          type: '기타',
          title: '지원 기기, 사양을 알고 싶어요.',
          content: '사용하시는 기기는 운영체제에 따라 차이가 있습니다.\nIOS(애플) 13 이상, 안드로이드(갤럭시) 버전에서는 6.0 이상이어야 앱 설치와 이용이 가능합니다.',
          hidden: true,
        },
        {
          id: 3,
          type: '기타',
          title: '다이아(보석)가 모두 사라졌어요.',
          content: '간혹 이용권과 아이디를 공유할 경우, 다른 프로필을 사용하고 있는 아이가 접속하여 다이아(보석)을 모두 사용하기도 합니다. 프로필 공유로 인해 발생하는 문제는 당사에서 해결해 드릴 수 없다는 점을 안내드립니다.\n' +
            '시스템 오류라고 생각되신다면, 애플리케이션 내 고객센터로 문의를 남겨주시면 영업시간 내에 확인하여 신속히 답변드리겠습니다.\n' +
            '애플리케이션에서 고객센터 문의하기 남기는 방법: 학습 설정 > 고객 센터(좌측 세 번째 탭)',
          hidden: true,
        },
        {
          id: 4,
          type: '기타',
          title: '스테이지에 오류(버그)가 있어요.',
          content: '일프로연산 이용에 불편을 드려 진심으로 죄송합니다. 애플리케이션 내 고객센터로 관련 내용을 남겨주시면 영업시간 내에 확인하여 신속히 수정 및 안내드리도록 하겠습니다.',
          hidden: true,
        },
        {
          id: 5,
          type: '기타',
          title: '아이스크림 홈런에 있는 일프로연산과 무슨 차이가 있나요?',
          content: '아이스크림 홈런에는 일프로연산의 학습 콘텐츠 일부가 탑재되어 있습니다.\n일프로연산에서는 아이스크림 홈런에 없는 모든 연산 학습 콘텐츠와 아이들이 흥미롭게 학습할 수 있도록 돕는 다양한 콘텐츠가 추가로 탑재되어 있습니다.',
          hidden: true,
        }
      ]
    },
  ];
  const location = useLocation();
  const [showFaq1, setShowFaq1] = useState(false);
  const faq1 = useRef(null);
  const tabsRef = useRef([]); // 각 탭에 대한 참조를 저장하기 위한 ref 객체 생성
  const containerRef = useRef();

  // 한 페이지당 표시할 항목 수
  const itemsPerPage = 6;

  // 현재 페이지, 현재 탭, 그리고 전체 FAQ 데이터 상태
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(0); // 0으로 초기화, 첫 번째 탭 선택
  const [faqData, setFaqData] = useState(initialData);

  // 특정 FAQ 항목의 hidden 상태를 토글하는 함수
  const toggleHidden = (tabIndex, faqId) => {
    const newData = faqData.map((tab, index) => {
      if (index === tabIndex) {
        return {
          ...tab,
          faq: tab.faq.map(faq =>
            faq.id === faqId ? { ...faq, hidden: !faq.hidden } : faq
          )
        };
      }
      return tab;
    });
    setFaqData(newData);
  };

  // 현재 페이지 데이터 계산
  const currentData = faqData[currentTab].faq
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // 전체 페이지 수 계산
  const totalItems = faqData[currentTab].faq.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 페이지 변경 함수
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 이전 페이지로 이동
  const goToPrevPage = () => {
    setCurrentPage(current => Math.max(current - 1, 1));
  };

  // 다음 페이지로 이동
  const goToNextPage = () => {
    setCurrentPage(current => Math.min(current + 1, totalPages));
  };

  // 탭 변경 함수
  const changeTab = (index) => {
    setCurrentTab(index);
    setCurrentPage(1); // 탭을 변경할 때 페이지를 처음으로 리셋
    scrollToTab(index);
  };

  const scrollToTab = (index) => {
    if (containerRef.current && tabsRef.current[index]) {
      const tabElement = tabsRef.current[index];
      const containerElement = containerRef.current;
      const tabLeft = tabElement.offsetLeft;
      const tabWidth = tabElement.offsetWidth;
      const containerWidth = containerElement.offsetWidth;
      const scrollTo = tabLeft - (containerWidth / 2) + (tabWidth / 2);
      containerElement.scroll({
        left: scrollTo,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    setShowFaq1(true);
  }, []);

  const images = {
    SoundOnOffButtonIphone: SoundOnOffButtonIphone,
    ControlCenterIpad: ControlCenterIpad,
    ControlCenterIpad2: ControlCenterIpad2,
    StartGuide1: StartGuide1,
    StartGuide2: StartGuide2,
    StartGuide3: StartGuide3,
    StartGuide4: StartGuide4,
    StudyGuide1: StudyGuide1,
    StudyGuide2: StudyGuide2,
    StudyGuide3: StudyGuide3,
    StudyGuide4: StudyGuide4,
  };

  const renderContent = (content) => {
    return content.split('\n').flatMap((line, lineIndex, array) => {
      const lineParts = line.split(/(\[img\].*?\[\/img\]|\{red\}.*?\{\/red\})/);
      const lineElements = lineParts.map((part, partIndex) => {
        if (part.startsWith('[img]')) {
          const imageName = part.match(/\[img\](.*?)\[\/img\]/)[1];
          return <img src={images[imageName]} alt="" key={`${lineIndex}-${partIndex}`} className={'w-[91.111vw] sm:w-[41.667vw]'}/>;
        } else if (part.startsWith('{red}')) {
          const redText = part.match(/\{red\}(.*?)\{\/red\}/)[1];
          return <span style={{ color: 'red' }} key={`${lineIndex}-${partIndex}`}>{redText}</span>;
        } else if (part.startsWith('[link]')) {
          const linkUrl = part.match(/\[link\](.*?)\[\/link\]/)[1];
          return <a href={linkUrl} target="_blank" rel="noopener noreferrer" key={`${lineIndex}-${partIndex}`} className={'text-[#2046E4]'}>{linkUrl}</a>;
        }
        return <span key={`${lineIndex}-${partIndex}`}>{part}</span>;
      });

      // 줄 바꿈 추가 (마지막 줄 제외)
      return lineIndex < array.length - 1 ? [...lineElements, <br key={`br-${lineIndex}`}/>] : lineElements;
    });
  };

  const renderContent2 = (item) => {
    return (
      <div>
        <img src={images[item.image]} alt={''} className={'w-[91.111vw] sm:w-[18.75vw] h-[50vw] sm:h-[10.417vw]'}/>
        <div className={'h-[16.667vw] sm:h-[4.063vw] font-[pretendard-b] text-[5.556vw] sm:text-[1.354vw] text-[#3E3E40] pl-[5.556vw] sm:pl-[1.042vw] flex items-center'}>
          {item.title}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // url이 변경될 때마다 실행될 로직 ex) 뒤로 가기 버튼 클릭
    let pathname = location.pathname;
    let pathComponents = pathname.split('/');

    // 필요없는 값 지우기.
    pathComponents = pathComponents.filter((el) => {
      return el !== null && el !== undefined && el !== '';
    });
    if (pathComponents[0] === 'faq') {
      if (pathComponents[1]) {
        setCurrentTab(parseInt(pathComponents[1]));

        // DOM 요소들이 준비되기를 기다린 후 스크롤 실행
        setTimeout(() => scrollToTab(parseInt(pathComponents[1])), 100);
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로 이동
  }, []);

  return (
    <div className={'w-[100vw] overflow-hidden'}>
      <div className={'block sm:hidden'}>
        <ScrollToTopButton/>
      </div>
      <div className={'relative h-[101.389vw] sm:h-[34.271vw]'} ref={faq1}>
        <CSSTransition in={showFaq1} timeout={700} classNames={'faq-1-1'} mountOnEnter unmountOnExit>
          <div className={'absolute top-[36.111vw] sm:top-[13.75vw] left-[34.722vw] sm:left-[44.792vw]'}>
            <div>
              <img src={HeadsetDdui} alt={'headset_ddui'} className={'w-[30.556vw] sm:w-[10.417vw]'}/>
            </div>
            <div className={'mt-[4.444vw] sm:mt-[1.563vw] text-center font-[nanum-square-round-eb] text-[7.222vw] sm:text-[2.292vw] leading-none'}>
              궁금해요
            </div>
          </div>
        </CSSTransition>
      </div>
      <CSSTransition in={showFaq1} timeout={900} classNames={'faq-1-2'} mountOnEnter unmountOnExit>
        <div>
          <div className={'block sm:flex sm:justify-center overflow-scroll sm:overflow-auto scrollable-div ml-[4.444vw] sm:ml-auto'} ref={containerRef}>
            <div className={'w-[277.778vw] sm:w-[62.5vw]'}>
              {/* 탭 네비게이션 */}
              <div className="h-auto sm:h-[2.396vw] flex font-[nanum-square-round-eb] text-[5.556vw] sm:text-[1.354vw] border-b-[1.222vw] sm:border-b-[0.26vw] border-[#D9D9D9]">
                {faqData.map((tab, index) => (
                  <button
                    key={index}
                    ref={el => tabsRef.current[index] = el} // 참조 설정
                    onClick={() => changeTab(index)}
                    className={`w-[20%] relative text-center ${currentTab === index ? 'text-[#2046E4]' : ''}`}
                  >
                    {tab.tab}
                    {currentTab === index ? (<div className={'w-full h-[1.111vw] sm:h-[0.26vw] absolute bottom-[-1.111vw] sm:bottom-[-0.234vw] bg-[#2046E4] rounded-full'}></div>) : (null)}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className={'flex justify-center'}>
            <div className={'w-[91.111vw] sm:w-[62.5vw]'}>
              <div className={'mt-[5vw] sm:mt-[3.125vw]'}>
                {currentTab === 0 || currentTab === 1 || currentTab === 4 ? (
                  // FAQ 항목
                  currentData.map((item) => (
                    <div key={item.id} className={'w-full border-b border-[#d9d9d9]'}>
                      <div className={'h-[30.556vw] sm:h-[6.25vw] block sm:flex justify-center relative'}>
                        <div className={'w-auto sm:w-[10.938vw] h-auto sm:h-full absolute sm:static top-[5.556vw] left-[2.778vw] flex justify-center items-center'}>
                          <div
                            className={'w-[21.111vw] sm:w-[5.417vw] h-[6.944vw] sm:h-[1.875vw] flex justify-center items-center border border-[#4D4D4D] rounded-full font-[pretendard-sb] text-[4.167vw] sm:text-[1.042vw] text-[#4D4D4D] leading-none'}>
                            {item.type}
                          </div>
                        </div>
                        <div
                          className={'w-[78.889vw] sm:w-[46.807vw] h-[6.111vw] sm:h-full absolute sm:static top-[18.056vw] left-[2.778vw] flex items-center font-[pretendard-b] text-[5vw] sm:text-[1.042vw] text-[#3E3E40] leading-snug sm:leading-none'}>
                          {item.title}
                        </div>
                        <div onClick={() => toggleHidden(currentTab, item.id)}
                             className={'w-[6.667vw] sm:w-[4.756vw] h-[6.667vw] sm:h-full absolute sm:static top-[18.056vw] left-[86.111vw] flex items-center cursor-pointer'}>
                          {
                            item.hidden ? (
                              <Down className={'w-[3.106vw] sm:w-[0.97vw]'}/>
                            ) : (
                              <Up className={'w-[3.106vw] sm:w-[0.97vw]'}/>
                            )
                          }
                        </div>
                      </div>
                      <div className={`px-[2.778vw] sm:px-[8.854vw] pb-[5.556vw] sm:pb-[2.083vw] font-[pretendard-m] text-[#3E3E40] text-[4.444vw] sm:text-[1.146vw] ${item.hidden ? 'hidden' : ''}`}>
                        {renderContent(item.content)}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={'static sm:flex justify-center'}>
                    <div className={'w-[60.417vw] grid grid-cols-1 sm:grid-cols-3 gap-[5.556vw] sm:gap-[2.083vw] mt-[4.444vw] sm:mt-auto'}>
                      {currentData.map((item) => (
                        <a key={item.id} href={item.link} target={'_blank'}
                           className={'w-[91.111vw] sm:w-[18.75vw] h-[66.667vw] sm:h-[14.479vw] block rounded-[8.333vw] sm:rounded-[1.563vw] overflow-hidden shadow-[0_4px_10px_0_rgba(0,0,0,0.15)]'}>
                          {renderContent2(item)}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition in={showFaq1} timeout={900} classNames={'faq-1-2'} mountOnEnter unmountOnExit>
        {currentTab === 0 || currentTab === 1 || currentTab === 4 ? (
          // 페이지 네비게이션
          <div className="mt-[11.111vw] sm:mt-[2.344vw] mb-[11.111vw] sm:mb-[2.344vw] flex justify-center">
            <div className={'flex justify-center'}>
              <button onClick={goToPrevPage} disabled={currentPage === 1} className={'w-[8.889vw] sm:w-[1.875vw] h-[8.889vw] sm:h-[1.875vw] flex justify-center items-center'}>
                <Prev className={'w-[2.439vw] sm:w-[0.515vw] h-auto'}/>
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button key={index} onClick={() => goToPage(index + 1)}
                        className={`w-[8.889vw] sm:w-[1.875vw] h-[8.889vw] sm:h-[1.875vw] flex justify-center items-center rounded-full font-[pretendard-b] text-[5vw] sm:text-[1.146vw] ml-[3.333vw] sm:ml-[1.042vw] leading-none ${currentPage === index + 1 ? 'bg-[#2046e4] text-[#ffffff]' : 'text-[#4D4D4D]'}`}>
                  {index + 1}
                </button>
              ))}
              <button onClick={goToNextPage} disabled={currentPage === totalPages}
                      className={'w-[8.889vw] sm:w-[1.875vw] h-[8.889vw] sm:h-[1.875vw] flex justify-center items-center ml-[3.333vw] sm:ml-[1.042vw]'}>
                <Next className={'w-[2.439vw] sm:w-[0.515vw] h-auto'}/>
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-[11.111vw] sm:mt-[9.375vw]">
          </div>
        )}
      </CSSTransition>
      <CSSTransition in={showFaq1} timeout={900} classNames={'faq-1-2'} mountOnEnter unmountOnExit>
        <div className={'flex justify-center mb-[22.222vw] sm:mb-[6.667vw]'}>
          <div className={'w-[91.111vw] sm:w-[62.5vw] h-[102.5vw] sm:h-[17.188vw] bg-[#EFF4FF] rounded-[6.944vw] sm:rounded-[1.563vw] pl-auto sm:pl-[5.208vw] flex sm:items-center'}>
            <div className={'hidden sm:block'}>
              <div className={'font-[nanum-square-round-eb] text-[1.563vw] leading-none'}>
                궁금하신 점은 고객센터로 문의해 주세요.
              </div>
              <div className={'flex items-center mt-[0.833vw] leading-none'}>
                <div className={'w-[1.563vw] h-[1.563vw] flex justify-center items-center'}>
                  <Headset className={'w-[1.563vw]'}/>
                </div>
                <div className={'font-[pretendard-b] text-[1.354vw] text-[#3E3E40] ml-[0.417vw]'}>
                  1811-0608
                </div>
              </div>
              <a
                target={'_blank'}
                href={'https://talk.naver.com/ct/w483w3?frm=psf'}
                className={'w-[16.667vw] h-[3.509vw] border-[0.104vw] border-[#000000] rounded-[0.521vw] flex justify-center items-center mt-[1.25vw] cursor-pointer'}>
                <NaverTalk className={'w-[1.146vw]'}/>
                <div className={'font-[pretendard-b] text-[1.146vw] ml-[0.573vw]'}>
                  스마트스토어 톡톡 문의하기
                </div>
              </a>
              <div className={'font-[pretendard-b] text-[1.042vw] mt-[1.302vw] leading-none'}>
                상담 가능 시간 월~금 09:00 ~ 18:00 (점심시간 12:30 ~ 13:30)
              </div>
              <div className={'font-[pretendard-m] text-[1.042vw] mt-[0.781vw] leading-none'}>
                주말과 공휴일에 남겨주신 문의는 상담일에 순차적으로 답변해 드려요.
              </div>
            </div>
            <div className={'block sm:hidden w-full'}>
              <div className={'font-[nanum-square-round-eb] text-[5.556vw] text-center leading-snug mt-[11.111vw]'}>
                궁금하신 점은<br/>
                고객센터로 문의해 주세요.
              </div>
              <div className={'flex justify-center items-center mt-[3.333vw] leading-none'}>
                <div className={'w-[7.222vw] sm:w-[1.563vw] h-auto sm:h-[1.563vw] flex justify-center items-center'}>
                  <Headset className={'w-[7.222vw] sm:w-[1.563vw] h-auto'}/>
                </div>
                <div className={'font-[pretendard-b] text-[5.556vw] text-[#3E3E40] ml-[2.222vw]'}>
                  1811-0608
                </div>
              </div>
              <div className={'flex justify-center mt-[4.444vw]'}>
                <a
                  target={'_blank'}
                  href={'https://talk.naver.com/ct/w483w3?frm=psf'}
                  className={'w-[81.944vw] h-[18.056vw] border-[0.556vw] border-[#000000] rounded-[2.778vw] flex justify-center items-center'}>
                  <NaverTalk className={'w-[5.556vw] sm:w-[1.146vw] h-auto'}/>
                  <div className={'font-[pretendard-b] text-[5.556vw] ml-[2.778vw]'}>
                    스마트스토어 톡톡 문의하기
                  </div>
                </a>
              </div>
              <div className={'font-[pretendard-b] text-[5vw] text-center mt-[6.667vw] leading-none'}>
                월~금 09:00 ~ 18:00
              </div>
              <div className={'font-[pretendard-m] text-[4.444vw] text-center mt-[1.111vw] leading-none'}>
                (점심시간 12:30 ~ 13:30)
              </div>
              <div className={'font-[pretendard-m] text-[4.167vw] text-center mt-[5.556vw] leading-tight'}>
                주말과 공휴일에 남겨주신 문의는<br/>
                상담일에 순차적으로 답변해 드려요.
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <Footer/>
    </div>
  );
};

export default Faq;
