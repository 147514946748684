import PurchaseBg1 from '../../resource/image/purchase_bg_1.png'
import HelloDdui from '../../resource/image/hello_ddui.png'
import DduiHand from '../../resource/image/ddui_hand.png'
import Star1 from '../../resource/image/star1.png'
import Star2 from '../../resource/image/star2.png'
import Star3 from '../../resource/image/star3.png'
import Star4 from '../../resource/image/star4.png'
import Star5 from '../../resource/image/star5.png'
import Star6 from '../../resource/image/star6.png'
import MobilePurchaseBg1 from '../../resource/image/mobile_purchase_bg_1.png'
import Footer from '../../component/Footer'
import { useEffect } from 'react'
import ScrollToTopButton from '../../component/ScrollToTopButton'

const Purchase = () => {
  useEffect(() => {
    window.scrollTo(0, 0) // 페이지 로드 시 스크롤을 최상단으로 이동
  }, [])

  return (
    <div className={'w-[100vw] overflow-hidden'}>
      <div className={'block sm:hidden'}>
        <ScrollToTopButton/>
      </div>
      <div className={'mt-[36.111vw] sm:mt-[13.75vw] mb-[22.222vw] sm:mb-[6.667vw] relative'}>
        <picture className={'relative'}>
          <source media="(min-width: 640px)" srcSet={PurchaseBg1}/>
          <source media="(max-width: 639px)" srcSet={MobilePurchaseBg1}/>
          <img src={PurchaseBg1} alt="purchase_bg_1" className={'w-full'}/>
        </picture>
        <div className={'absolute top-[111.111vw] sm:top-[22.783vw] left-[16.667vw] sm:left-[40.521vw]'}>
          <img src={HelloDdui} alt={'hello_ddui'} className={'w-[66.667vw] sm:w-[18.958vw]'}/>
        </div>
        <div className={'absolute top-[161.667vw] sm:top-[37.24vw] left-[60vw] sm:left-[52.865vw]'}>
          <img src={DduiHand} alt={'ddui_hand'} className={'w-[18.333vw] sm:w-[5.208vw] animate-rotate origin-bottom-left'}/>
        </div>
        <div className={'w-[41.667vw] sm:w-auto absolute top-[108.056vw] sm:top-[23.75vw] left-[-17.778vw] sm:left-[2.5vw]'}>
          <img src={Star1} alt={'star_1'} className={'w-[41.667vw] sm:w-[17.188vw] animate-rotatenfade'}/>
        </div>
        <div className={'hidden sm:block absolute top-[9.635vw] left-[14.948vw]'}>
          <img src={Star2} alt={'star_2'} className={'w-[10.99vw] animate-rotatenfade2'}/>
        </div>
        <div className={'hidden sm:block absolute top-[25vw] left-[27.76vw]'}>
          <img src={Star3} alt={'star_3'} className={'w-[9.167vw] animate-rotatenfade'}/>
        </div>
        <div className={'hidden sm:block absolute top-[22.396vw] left-[62.917vw]'}>
          <img src={Star4} alt={'star_4'} className={'w-[9.167vw] animate-rotatenfade2'}/>
        </div>
        <div className={'w-[22.222vw] sm:w-auto absolute top-[84.444vw] sm:top-[9.74vw] left-[74.167vw] sm:left-[74.844vw]'}>
          <img src={Star5} alt={'star_5'} className={'w-[22.222vw] sm:w-[14.896vw] animate-rotatenfade'}/>
        </div>
        <div className={'w-[33.333vw] sm:w-auto absolute top-[161.389vw] sm:top-[28.906vw] left-[78.056vw] sm:left-[78.646vw]'}>
          <img src={Star6} alt={'star_6'} className={'w-[33.333vw] sm:w-[16.719vw] animate-rotatenfade2'}/>
        </div>
        <div className={'w-full absolute top-[36.944vw] sm:top-[4.792vw] font-[nanum-square-round-eb] text-[#ffffff] text-[8.333vw] sm:text-[3.125vw] text-center leading-none'}>
          일프로연산 이용권
        </div>
        <div className={'hidden sm:block absolute top-[9.688vw] left-[50%] -translate-x-1/2 font-[pretendard-m] text-[#ffffff] text-[2.083vw] leading-none'}>
          네이버 쇼핑에서 지금 바로 특별한 콘텐츠를 이용해 보세요!
        </div>
        <div className={'block sm:hidden w-full absolute top-[50.278vw] font-[pretendard-m] text-[#ffffff] text-[5.556vw] text-center leading-snug'}>
          네이버 쇼핑에서 지금 바로<br/>
          특별한 콘텐츠를 이용해 보세요!
        </div>
        <a
          target={'_blank'}
          href={'https://smartstore.naver.com/1promath'}
          className={'w-[42.5vw] sm:w-[13.49vw] h-[15.556vw] sm:h-[4.688vw] absolute top-[73.889vw] sm:top-[14.167vw] left-[50%] -translate-x-1/2 bg-[#FFED6C] rounded-[4.167vw] sm:rounded-[1.042vw] flex justify-center items-center font-[nanum-square-round-eb] text-[#191982] text-[5.556vw] sm:text-[1.979vw] leading-none cursor-pointer'}>
          구매하기
        </a>
      </div>
      <Footer/>
    </div>
  )
}

export default Purchase