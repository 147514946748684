import { Fragment, useState } from 'react';

const PrivacyPage = () => {
  const initialData = [
    {
      content: '개인정보 취급방침\n' +
        ' \n' +
        '\n' +
        '주식회사 매쓰마스터의 서비스인 \'일프로연산\'은 회원의 개인정보를 매우 소중히 생각합니다. 일프로연산은 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」 을 비롯한 개인정보 관련 법령을 준수하고 있습니다. 알려주신 개인정보는 동의하신 목적과 범위 내에서만 이용되며, 별도의 동의 없이는 결코 제3자에게 제공되지 않습니다. 또한 기본적 인권 침해의 우려가 있는 민감한 개인정보는 수집하지 않습니다. 본 개인정보취급방침은 개인정보를 안전하게 취급하기 위한 일프로연산의 정책과 자세한 사항을 알려드리기 위해 마련되었으며 앱과 웹사이트 (https://1promath.com/privacy)에서 언제든 확인하실 수 있습니다.\n' +
        '○ 본 방침은부터 2019년 4월 4일부터 시행됩니다.\n' +
        '\n' +
        '1. 수집항목 및 수집방법\n' +
        '\n' +
        '일프로연산은 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집하고 있으며, 이에 대한 동의를 얻고 있습니다. \n' +
        '\n' +
        '가. 수집하는 개인정보의 항목\n' +
        'i. 필수 항목\n' +
        ' - 닉네임(이름), 나이\n' +
        ' - (학생 회원) 학년\n' +
        ' \n' +
        'ii. 선택 항목\n' +
        ' - 전화번호\n' +
        ' \n' +
        'iii. 자동 수집 항목\n' +
        ' - 기기고유번호, 접속 로그, 접속 아이피, 쿠키, 서비스 이용 기록, 결제 기록, 불량 이용 기록\n' +
        '\n' +
        '나. 개인정보 수집방법\n' +
        ' - 회원가입, 회원정보 수정, 전화 또는 팩스, 서비스 이용\n' +
        '\n' +
        '\n' +
        '2. 이용목적\n' +
        '\n' +
        '일프로연산은 수집한 개인정보를 다음의 목적을 위해 활용합니다.\n' +
        '\n' +
        '가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산에 활용합니다.\n' +
        '- 컨텐츠 제공, 특정 맞춤 서비스 제공, 본인인증, 구매 및 요금 결제\n' +
        '\n' +
        '나. 회원관리를 위해 일부 회원 정보를 활용합니다.\n' +
        '- 회원제 서비스 이용 및 인증 서비스에 따른 본인확인, 개인 식별, 불량회원(이용약관 제 10조 회원의 의무 각항을 위반하거나 성실히 수행하지 않은 회원)의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달\n' +
        '\n' +
        '다. 서비스 품질 향상 및 마케팅, 광고에 활용 합니다.\n' +
        '- 서비스 이용 통계 수집을 통한 서비스 만족도 분석, 신규 서비스 개발 및 맞춤서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계, 서비스의 유효성 확인\n' +
        '\n' +
        '\n' +
        '3. 보유기간 및 파기방법\n' +
        '\n' +
        '회원이 입력한 개인정보는 원칙적으로 각 개인정보의 이용목적이 달성된 후에 지체 없이 기록을 재생할 수 없는 기술적 방법을 사용하여 파기됩니다. 단, 법령에 의해 보존해야만 하는 경우 또는 불량이용 방지와 관련 분쟁 방지를 위하여 아래와 같이 일정 기간 동안 회원정보를 보존합니다.\n' +
        '\n' +
        'i. 계약 또는 청약철회 등에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률, 5년\n' +
        'ii. 대금결제 및 재화 등의 공급에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률, 5년\n' +
        'iii. 소비자의 불만 또는 분쟁처리에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률, 3년\n' +
        'iv. 표시, 광고에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률, 6개월\n' +
        'v. 전자금융 거래에 관한 기록 : 전자금융거래법, 5년\n' +
        'vi. 서비스 방문기록, 접속로그, 접속 아이피 : 통신비밀보호법, 3개월\n' +
        'vii. 본인 확인에 관한 기록 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 6개월\n' +
        'viii. 이메일 주소, 서비스 이용기록 : 부정이용 방지를 위해, 회원 탈퇴일로부터 3개월\n' +
        '\n' +
        '\n' +
        '4. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항\n' +
        '\n' +
        '일프로연산은 이용자에게 특화된 맞춤 서비스를 제공하기 위해서 \'쿠키(cookie)\' 등을 이용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장되기도 합니다. 일프로연산은 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정보 및 방문 회수 파악 등을 통한 타겟 마케팅을 위하여 쿠키를 사용합니다.\n' +
        '이용자는 쿠키 설치에 대한 선택권을 가지고 있으며 이용자는 웹브라우저에서 옵션을 설정함으로써 쿠키를 허용하거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 저장을 거부하는 경우 로그인이 필요한 서비스 이용에 어려움이 있을 수 있습니다.\n' +
        '쿠키 설정 거부 방법 예: 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.\n' +
        '설정방법 예(모바일의 경우) : 웹브라우저> 메뉴 > 설정 > 개인정보 보호 및 보안 > 쿠키허용 체크 해제\n' +
        '\n' +
        '\n' +
        '5. 개인정보취급방침의 변경\n' +
        '\n' +
        '일프로연산은 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보취급방침을 변경할 수 있습니다. 이 경우 변경 사항을 게시하며, 변경된 개인정보 취급방침은 게시한 날로부터 7일 후부터 효력이 발생합니다.\n' +
        '\n' +
        '\n' +
        '6. 개인정보의 안전성 확보 조치 <매쓰마스터>(\'일프로연산\')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.\n' +
        '\n' +
        'i. 정기적인 자체 감사 실시\n' +
        '개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.\n' +
        'ii. 개인정보 취급 직원의 최소화 및 교육\n' +
        '개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.\n' +
        'iii. 내부관리계획의 수립 및 시행\n' +
        '개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.\n' +
        'iv. 개인정보에 대한 접근 제한\n' +
        '개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.\n' +
        '\n' +
        '\n' +
        '7. 회원의 권리, 문의방법\n' +
        '\n' +
        '회원은 언제든지 개인정보를 조회하거나 수정할 수 있으며 수집 및 이용에 대한 동의를 철회하거나 가입 해지를 요청할 수 있습니다. 구체적으로는 서비스 내 프로필설정 기능을 통해 변경 하거나, 고객센터를 통해 개인정보관리책임자에게 전화 또는 이메일로 연락하시면 지체 없이 처리하여 그 결과를 알려드립니다. 또한 탈퇴 후에도 고객센터를 통하여 개인정보의 파기를 확인받으실 수 있습니다. 개인정보보호와 관련한 문의, 불만 기타 사항은 일프로연산 개인정보보호책임자에게 문의하실 수 있습니다.\n' +
        '\n' +
        '\n' +
        '8. 개인정보보호책임자 및 연락처\n' +
        '\n' +
        '회원은 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 회원의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.\n' +
        '\n' +
        '개인정보보호책임자 : 최정명\n' +
        '직위 : 최고기술책임자\n' +
        '전화 : 070-4618-1616\n' +
        '이메일 : 1promath@naver.com\n' +
        '\n' +
        '기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.\n' +
        '- 개인정보침해신고센터 (www.118.or.kr / 118)\n' +
        '- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)\n' +
        '- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)\n' +
        '- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)'
    },
    {
      content: 'PRIVACY POLICY\n' +
        '\n' +
        'At \'Math Master\', Inc. (“we,” “our,” “us” or “the Company”) we are committed to being transparent with our data and privacy processes and to safeguarding the on-line and information privacy of the users of our websites and applications. This privacy policy describes the information collected by us through our apps and websites and how the information is used and/or disclosed. We believe that providing this information empowers you to feel secure about the website and app choices you make.\n' +
        '\n' +
        'Our websites provide information to parents, educators, schools, educational settings and other interested community members about our company and products-- play-based learning applications to empower all children to be independent learners. The website is not for the use of children under the age of 16. We do not knowingly collect any personal information (as defined below) from children under age 16. Our websites and learning applications together are referred to as “Services” in this Policy.\n' +
        '\n' +
        '○ This policy will take effect on April 4, 2019.\n' +
        '\n' +
        '1. NO THIRD-PARTY ADVERTISING\n' +
        '\n' +
        '\'1proMath - arithmetic workbook(Add, Sub, Multiple)\' apps are designed to help children experience success in their learning. They do not have third-party advertisements so that young learners stay focused on their learning experience. Any in-app upgrades or purchases, links to our apps in the App Store, links toour websites or social media pages can only be accessed through a parental gate. These apps do not enable children to make their personal information publicly available.\n' +
        '\n' +
        '2. NO SALE OF DATA\n' +
        '\n' +
        'We do not sell or rent any personally identifiable data to any third parties. We share data only as necessary with service providers who perform functions and services for us and who maintain the same level of data protection standards as this Privacy Policy.\n' +
        '\n' +
        '3. WHAT INFORMATION WE COLLECT AND HOW WE PROCESS IT\n' +
        '\n' +
        '1st. Items of personal information to be collected i. Required -Name (nickname), age -(Student Member) Grade ii. Optional item - Phone number iii. Automatic collection items -Device identification number, access log, connection IP, cookie, service use record, payment record, bad use record\n' +
        '\n' +
        '2nd. How to collect personal information -Membership registration, member information modification, telephone or fax, service use\n' +
        '\n' +
        '4. Matters concerning the installation, operation and rejection of automatic personal information collection devices\n' +
        '\n' +
        '\'1proMath - arithmetic workbook(Add, Sub, Multiple)\' uses\'cookies\' to provide customized services specialized to users. Cookies are small text files that the server used to operate the website sends to the user\'s browser and are sometimes stored on the user\'s computer hard disk. \'1proMath - arithmetic workbook(Add, Sub, Multiple)\' uses cookies for targeted marketing by analyzing access frequency and visit time, identifying users\' tastes and interests, tracking traces, and identifying information on participation in various events and number of visits. The user has the option of installing cookies, and the user can accept cookies or refuse to store all cookies by setting options in the web browser. However, if you refuse to save, you may have difficulty using services that require login. How to reject cookie settings Example: To reject cookie settings, you can allow all cookies, check each time you save cookies, or refuse to save all cookies by selecting the option of the web browser you are using. Example of setting method (for mobile): Web browser> Menu> Settings> Privacy and security> Uncheck Allow Cookies\n' +
        '\n' +
        '5. Change of personal information handling policy\n' +
        '\n' +
        '\'1proMath - arithmetic workbook(Add, Sub, Multiple)\' can change the privacy policy for the purpose of reflecting changes in laws or services. In this case, the changes will be posted, and the changed privacy policy will take effect 7 days after the date of posting.\n' +
        '\n' +
        '6. Measures to ensure the safety of personal information \'Math Master\' (\'1proMath - arithmetic workbook(Add, Sub, Multiple)\') takes technical, administrative and physical measures necessary to ensure safety as follows in accordance with Article 29 of the Personal Information Protection Act.\n' +
        '\n' +
        'Conduct regular self-audit We conduct our own audits on a regular basis (quarterly) to ensure the safety of handling personal information. ii. Minimization and training of personnel handling personal information We are implementing measures to manage personal information by designating employees who handle personal information and minimizing them by limiting them to the person in charge. iii. Establishment and implementation of internal management plan We have established and implemented an internal management plan for safe handling of personal information. iv. Restricted access to personal information Necessary measures are taken to control access to personal information by granting, changing, and canceling access rights to the database system that processes personal information, and unauthorized access from outside is controlled using an intrusion prevention system.\n' +
        '\n' +
        '7. Member\'s rights, inquiry method\n' +
        '\n' +
        'Members can view or modify personal information at any time, withdraw their consent to collection and use, or request termination of subscription. Specifically, if you change it through the profile setting function in the service or contact the person in charge of personal information management by phone or e-mail through the customer center, we will process it without delay and notify the result. In addition, even after withdrawal, you can receive confirmation of destruction of personal information through the customer center. For inquiries, complaints and other matters related to personal information protection, you can contact the person in charge of personal information protection in 1pro.\n' +
        '\n' +
        '8. Personal information protection officer and contact information\n' +
        '\n' +
        'Members can report any complaints related to personal information protection that occur while using the company\'s services to the person in charge of personal information protection or the department in charge. The company will provide prompt and sufficient answers to the member\'s report.\n' +
        '\n' +
        'Personal Information\n' +
        'Protection Officer: Jeongmyung Choi\n' +
        'Position: Chief Technology Officer\n' +
        'Phone: 070-4618-1616 Email: 1promath@naver.com'
    },
    {
      content: 'プライバシーポリシー\n' +
        '\n' +
        '\'Math Master\'、Inc。（「私たち」、「私たち」、「私たち」または「会社」）では、データとプライバシーのプロセスを透明にし、オンラインと情報のプライバシーを保護することに取り組んでいます。当社のウェブサイトおよびアプリケーションのユーザー。このプライバシーポリシーは、当社のアプリやWebサイトを通じて収集された情報と、その情報がどのように使用および/または開示されるかについて説明しています。この情報を提供することで、ウェブサイトやアプリの選択について安心できると確信しています。\n' +
        '\n' +
        '当社のウェブサイトは、保護者、教育者、学校、教育現場、その他の関心のあるコミュニティメンバーに、当社と製品に関する情報を提供します。これは、すべての子供が独立した学習者になるための遊びベースの学習アプリケーションです。このウェブサイトは16歳未満の子供を対象としていません。16歳未満の子供から故意に個人情報（以下に定義）を収集することはありません。当社のウェブサイトと学習アプリケーションを合わせて、このポリシーでは「サービス」と呼びます。 。\n' +
        '\n' +
        '○このポリシーは2019年4月4日に発効します。\n' +
        '\n' +
        '1.サードパーティの広告はありません\n' +
        '\n' +
        '「1proMath-算術ワークブック（追加、サブ、複数）」アプリは、子供たちが学習の成功を体験できるように設計されています。若い学習者が学習体験に集中できるように、サードパーティの広告はありません。アプリ内のアップグレードや購入、App Storeのアプリへのリンク、ウェブサイトやソーシャルメディアページへのリンクは、保護者のゲートからのみアクセスできます。これらのアプリでは、子供が自分の個人情報を公開することはできません。\n' +
        '\n' +
        '2.データの販売なし\n' +
        '\n' +
        '個人を特定できるデータを第三者に販売または貸与することはありません。当社は、必要に応じて、当社の機能とサービスを実行し、このプライバシーポリシーと同じレベルのデータ保護基準を維持しているサービスプロバイダーとデータを共有します。\n' +
        '\n' +
        '3.収集する情報とその処理方法\n' +
        '\n' +
        '1日 収集する個人情報の項目i。必須-名前（ニックネーム）、年齢-（学生会員）グレードii。オプションアイテム-電話番号iii。自動収集項目-デバイス識別番号、アクセスログ、接続IP、Cookie、サービス使用記録、支払い記録、不正使用記録\n' +
        '\n' +
        '2位。個人情報の収集方法-会員登録、会員情報の変更、電話またはファックス、サービスの利用\n' +
        '\n' +
        '4.自動個人情報収集装置の設置、運用、拒否に関する事項\n' +
        '\n' +
        '「1proMath-算術ワークブック（追加、サブ、複数）」は「クッキー」を使用して、ユーザーに特化したカスタマイズされたサービスを提供します。クッキーは、ウェブサイトの運営に使用されるサーバーがユーザーのブラウザに送信する小さなテキストファイルであり、ユーザーのコンピュータのハードディスクに保存されることもあります。「1proMath-算術ワークブック（Add、Sub、Multiple）」は、アクセス頻度と訪問時間を分析し、ユーザーの好みと興味を識別し、トレースを追跡し、さまざまなイベントへの参加と訪問数に関する情報を識別することにより、ターゲットマーケティングにCookieを使用します。ユーザーはCookieをインストールするオプションがあり、ユーザーはWebブラウザーでオプションを設定することにより、Cookieを受け入れるか、すべてのCookieの保存を拒否することができます。ただし、保存を拒否した場合、ログインが必要なサービスの利用が困難になる場合があります。Cookie設定を拒否する方法例：Cookie設定を拒否するには、使用しているWebブラウザーのオプションを選択して、すべてのCookieを許可するか、Cookieを保存するたびに確認するか、すべてのCookieの保存を拒否します。設定方法の例（モバイル用）：Webブラウザ>メニュー>設定>プライバシーとセキュリティ> Cookieを許可するのチェックを外します\n' +
        '\n' +
        '5.個人情報取り扱い方針の変更\n' +
        '\n' +
        '「1proMath-算術ワークブック（追加、サブ、複数）」は、法律またはサービスの変更を反映する目的でプライバシーポリシーを変更できます。この場合、変更は掲載され、変更されたプライバシーポリシーは掲載日から7日後に有効になります。\n' +
        '\n' +
        '6.個人情報の安全を確保するための措置「数学マスター」（「1proMath-算数ワークブック（追加、サブ、複数）」）は、個人の第29条に従い、安全を確保するために必要な技術的、管理的および物理的措置を講じます。情報保護法。\n' +
        '\n' +
        '定期的な自己監査の実施個人情報の安全性を確保するため、定期的（四半期ごと）に独自の監査を実施しています。ii。個人情報を取り扱う人員の最小化と訓練個人情報を取り扱う従業員を指定し、担当者に限定して最小化することにより、個人情報を管理するための対策を実施しています。iii。内部管理計画の策定と実施個人情報を安全に取り扱うための内部管理計画を策定し、実施しています。iv。個人情報へのアクセス制限個人情報を処理するデータベースシステムへのアクセス権を付与、変更、解除することにより、個人情報へのアクセスを管理するために必要な措置を講じます。\n' +
        '\n' +
        '7.会員の権利、お問い合わせ方法\n' +
        '\n' +
        'メンバーはいつでも個人情報を閲覧または変更したり、収集および使用への同意を撤回したり、サブスクリプションの終了を要求したりすることができます。具体的には、本サービスのプロファイル設定機能で変更したり、カスタマーセンターを通じて電話またはメールで個人情報管理担当者に連絡した場合、遅滞なく処理し、結果を通知します。また、引き出し後もカスタマーセンターを通じて個人情報の破壊確認を受け取ることができます。個人情報保護に関するお問い合わせ、苦情等については、1proの個人情報保護担当者にご連絡ください。\n' +
        '\n' +
        '8.個人情報保護責任者および連絡先情報\n' +
        '\n' +
        '会員は、当社のサービスの利用中に発生した個人情報保護に関する苦情を、個人情報保護担当者または担当部門に報告することができます。会社は会員の報告に対して迅速かつ十分な回答を提供します。\n' +
        '\n' +
        '個人情報\n' +
        '保護責任者：崔チョンミョン\n' +
        '役職：最高技術責任者\n' +
        '電話：070-4618-1616電子メール：1promath@naver.com'
    }
  ];
  const [activeTab, setActiveTab] = useState('ko');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      {/* Tab Navigation */}
      <div style={{ display: 'flex', borderBottom: '2px solid #ddd', marginBottom: '1rem' }}>
        <button
          style={{
            padding: '10px',
            border: 'none',
            background: activeTab === 'ko' ? '#f0f0f0' : 'transparent',
            cursor: 'pointer',
          }}
          onClick={() => handleTabChange('ko')}
        >
          한국어
        </button>
        <button
          style={{
            padding: '10px',
            border: 'none',
            background: activeTab === 'en' ? '#f0f0f0' : 'transparent',
            cursor: 'pointer',
          }}
          onClick={() => handleTabChange('en')}
        >
          English
        </button>
        <button
          style={{
            padding: '10px',
            border: 'none',
            background: activeTab === 'jp' ? '#f0f0f0' : 'transparent',
            cursor: 'pointer',
          }}
          onClick={() => handleTabChange('jp')}
        >
          日本語
        </button>
      </div>

      {/* Tab Content */}
      <div className={'p-[10px]'}>
        {activeTab === 'ko' && initialData[0].content.split('\n').map((value, index) => {
          return (
            <Fragment key={index}>
              {value}
              <br/>
            </Fragment>
          );
        })}
        {activeTab === 'en' && initialData[1].content.split('\n').map((value, index) => {
          return (
            <Fragment key={index}>
              {value}
              <br/>
            </Fragment>
          );
        })}
        {activeTab === 'jp' && initialData[2].content.split('\n').map((value, index) => {
          return (
            <Fragment key={index}>
              {value}
              <br/>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default PrivacyPage;